/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Wrapper from './../Wrapper'
import styles from './styles'

type Props = {
  className?: string,
  children: React$Node,
}

export default class Footer extends React.Component<Props> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { className, children } = this.props
    return (
      <footer className={merge(css(styles.container), className)}>
        <Wrapper className={css(styles.wrapper)}>
          <div className={css(styles.content)}>
            {React.Children.map(children, child => {
              if (child) {
                return React.cloneElement(child, {
                  className: css(styles.item),
                })
              }
              return null
            })}
          </div>
        </Wrapper>
      </footer>
    )
  }
}
