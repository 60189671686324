/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover, before, after } from 'glamor'
import { rem } from 'polished'
import { shadows, utils } from './../../theme'

const { BLACK_SHADOW_50 } = shadows
const { generateImagePath } = utils

const styles = StyleSheet.create({
  content: merge(
    {
      position: 'relative',
      marginTop: rem(30),
    },
    before({
      content: ' ',
      display: 'block',
      top: 0,
      left: rem(-100),
      position: 'absolute',
      zIndex: -1,
      width: rem(112),
      height: '100%',
      backgroundImage: `url(${generateImagePath('abstractpattern_home_behindscreens-left.png')})`,
      backgroundPosition: 'left bottom',
      backgroundSize: 'contain',
    }),
    after({
      content: ' ',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: rem(-112),
      zIndex: -1,
      width: rem(112),
      height: '100%',
      backgroundImage: `url(${generateImagePath('abstractpattern_home_behindscreens-right.png')})`,
      backgroundPosition: 'left top',
      backgroundSize: 'contain',
    }),
  ),
  slick: {
    ...BLACK_SHADOW_50,
    borderRadius: 4,
    overflow: 'hidden',
  },
  arrow: merge(
    {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(-50%)',
      top: '50%',
      height: rem(25),
      opacity: 0.5,
      cursor: 'pointer',
      outline: 'none',
    },
    hover({
      opacity: 1,
    }),
  ),
  leftArrow: {
    left: rem(-50),
  },
  rightArrow: {
    right: rem(-40),
  },
  disabledArrow: merge(
    {
      opacity: 0.25,
      cursor: 'default',
    },
    hover({
      opacity: 0.25,
    }),
  ),
})

export default styles
