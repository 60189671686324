/* @flow */
/* eslint indent: 0 */

import React from 'react'
import { css, merge } from 'glamor'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Link from './../Link'
import styles from './styles'

type Props = {
  className?: string,
  color: 'primary' | 'secondary',
  media: string,
  title: string,
  content: string,
  linkText?: string,
  linkTo?: string,
  href?: string,
  target?: string,
}

export default function InformationInsert(props: Props) {
  const { className, title, content, media, color, linkText, linkTo, href, target } = props
  return (
    <div
      className={merge(
        css(
          styles.container,
          color === 'primary' && styles.primaryContainer,
          color === 'secondary' && styles.secondaryContainer,
          media === 'offline' && styles.offlineContainer,
          media === 'compliance' && styles.complianceContainer,
          media === 'security' && styles.securityContainer,
          media === 'free' && styles.freeContainer,
          media === 'oncashregister' && styles.oncashregisterContainer,
          media === 'everywhere' && styles.everywhereContainer,
          media === 'multishop' && styles.multishopContainer,
        ),
        className,
      )}
    >
      <Title className={css(styles.title, styles.text)} level={5}>
        {title}
      </Title>
      <Paragraph className={css(styles.paragraph, styles.text)}>{content}</Paragraph>
      {(href || linkTo) &&
        linkText && (
          <Link className={css(styles.text, styles.link)} to={linkTo} href={href} target={target}>
            {linkText}
          </Link>
        )}
    </div>
  )
}
