/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import Link from 'gatsby-link'
import Image from './../Image'
import Title from './../Title'
import Span from './../Span'
import Paragraph from './../Paragraph'
import PostLink from './../PostLink'
import styles from './styles'

type Props = {
  className?: string,
  to: string,
  media: string,
  srcset?: string,
  meta: string,
  title: string,
  excerpt: string,
  categorie: string,
}

export default function Post(props: Props) {
  const { className, to, media, title, meta, excerpt, categorie, srcset } = props
  return (
    <Link className={merge(css(styles.container), className)} to={`/${to}`}>
      <Image className={css(styles.media)} src={media} srcset={srcset} />
      <Span size="small" className={css(styles.meta)}>
        {meta}, <span className={css(styles.categorie)}>{categorie}</span>
      </Span>
      <Title className={css(styles.title)} level={5}>
        {title}
      </Title>
      <Paragraph className={css(styles.paragraph)}>
        <span dangerouslySetInnerHTML={{ __html: excerpt }} />
      </Paragraph>
      <PostLink className={css(styles.link)}>En savoir plus</PostLink>
    </Link>
  )
}
