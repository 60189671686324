/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge } from 'glamor'

import { colors } from '../../theme'

const { NEUTRAL_COLOR_50 } = colors

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexWrap: 'wrap',
      fontWeight: 'normal',
      fontFamily: 'LibreFranklin-Regular',
      color: NEUTRAL_COLOR_50,
      paddingRight: 50,
    },
    {
      '> a': {
        marginRight: 0,
        marginLeft: 6,
      },
    },
  ),
  label: {
    marginRight: 20,
  },
})

export default styles
