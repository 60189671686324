/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import Icon from './../Icon'
import Span from './../Span'
import { colors } from './../../theme'
import styles from './styles'

const { SUCCESS_COLOR_50 } = colors

type Props = {
  children?: React$Node,
}

export default function ListItem(props: Props) {
  const { children } = props
  if (!children) return null
  return (
    <li className={css(styles.li)}>
      <Icon className={css(styles.icon)} name="check" size={18} color={SUCCESS_COLOR_50} />
      <Span className={css(styles.span)} size="medium">
        {children}
      </Span>
    </li>
  )
}
