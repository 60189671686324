/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { grid, colors, breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT } = breakpoints
const { column } = grid
const { NEUTRAL_COLOR_50, NEUTRAL_COLOR_70 } = colors

const styles = StyleSheet.create({
  main: merge(
    {
      ...column(1),
      marginTop: rem(32),
      paddingTop: rem(32),
      borderTopWidth: 1,
      borderTopColor: NEUTRAL_COLOR_70,
      borderTopStyle: 'solid',
      color: NEUTRAL_COLOR_50,
      letterSpacing: 0.5,
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(52),
      paddingTop: rem(40),
    }),
  ),
})

export default styles
