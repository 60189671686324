/* @flow */

import * as React from 'react'
import NativeLink from 'gatsby-link'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: string,
  to?: string,
  href?: string,
  className?: string,
  target?: '_blank',
  type?: 'primary' | 'secondary',
  size?: 'tiny' | 'small' | 'normal',
}

export default class Link extends React.Component<Props> {
  static defaultProps = {
    type: 'primary',
  }

  render() {
    const { children, to, href, target, className, type, size } = this.props
    return React.createElement(
      to ? NativeLink : 'a',
      {
        to,
        href,
        target,
        className: merge(
          css(
            styles.main,
            type === 'primary' && styles.primary,
            type === 'secondary' && styles.secondary,
            size === 'tiny' && styles.tiny,
            size === 'small' && styles.small,
            size === 'normal' && styles.normal,
          ),
          className,
        ),
      },
      children,
    )
  }
}
