/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  active?: boolean,
  children: string,
}

export default function DropdownTitle(props: Props) {
  const { children, className, active } = props
  return (
    <span className={merge(css(styles.main, active && styles.active), className)}>{children}</span>
  )
}
