/* @flow */

import { rem } from 'polished'

export const X_SMALL_BREAKPOINT = `(min-width: ${rem(384)})`
export const SMALL_BREAKPOINT = `(min-width: ${rem(544)})`
export const MEDIUM_BREAKPOINT = `(min-width: ${rem(768)})`
export const LARGE_BREAKPOINT = `(min-width: ${rem(1024)})`
export const HUGE_BREAKPOINT = `(min-width: ${rem(1392)})`
export const MASSIVE_BREAKPOINT = `(min-width: ${rem(1600)})`
