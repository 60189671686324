/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import { rem } from 'polished'
import styles from './styles'

type Props = {
  className?: string,
  labels: Array<string>,
  activeIndex: number,
  handleChangeTab: (index: number) => void,
}

export default function SliderNav(props: Props) {
  const { className, labels, handleChangeTab, activeIndex } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <ul className={css(styles.list)}>
        {labels.map((label, index) => (
          <li
            key={index}
            role="menuitem"
            className={css(styles.item, activeIndex === index && styles.activeItem)}
            tabIndex={index}
            onClick={() => handleChangeTab(index)}
          >
            {label}
          </li>
        ))}
      </ul>
      <div
        style={{
          transition: 'transform .125s ease',
          transform: `translateX(calc(100% / ${
            labels.length
          } * ${activeIndex} + 100% / ${labels.length * 2} - ${rem(20)}))`,
        }}
      >
        <div className={css(styles.indicator)} />
      </div>
    </div>
  )
}
