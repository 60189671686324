/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  className?: string,
  onMouseEnter?: Function,
  onMouseLeave?: Function,
}

class Dropdown extends React.Component<Props> {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { children, className, onMouseEnter, onMouseLeave } = this.props    
    return (
      <div
        className={merge(css(styles.container), className)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    )
  }
}

export default Dropdown
