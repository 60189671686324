/* @flow */

import * as React from 'react'
import Link from 'gatsby-link'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  to?: string,
  onClick?: Function,
}

export default function FooterLink(props: Props) {
  const { children, to, onClick } = props
  let linkComponent = Link
  if (to && to.includes(':')) linkComponent = 'a'
  if (typeof onClick === 'function') linkComponent = 'span'
  return (
    <li className={css(styles.li)}>
      {React.createElement(
        linkComponent, // $FlowFixMe
        Object.assign(
          {},
          to && to.includes(':') && { target: '_blank', href: to },
          to && { to },
          onClick && { onClick },
          { className: css(styles.link) },
        ),
        children,
      )}
    </li>
  )
}
