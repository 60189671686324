/* @flow */

import { merge, media } from 'glamor'
import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    media(SMALL_BREAKPOINT, {
      maxWidth: rem(600),
    }),
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(800),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(900),
    }),
  ),
  media: {
    marginTop: rem(12),
  },
  title: merge(
    {
      marginTop: rem(10),
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: rem(600),
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: 'auto',
    }),
  ),
  content: {
    textAlign: 'center',
    maxWidth: rem(750),
  },
})

export default styles
