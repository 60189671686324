/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors, fonts } from './../../theme'

const { NEUTRAL_COLOR_50, NEUTRAL_COLOR_80, NEUTRAL_COLOR_90 } = colors
const { LIBRE_FRANKLIN_REGULAR, LIBRE_FRANKLIN_SEMIBOLD } = fonts

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    marginTop: rem(22),
  },
  profilePic: {
    borderRadius: rem(50),
    backgroundColor: NEUTRAL_COLOR_90,
    width: rem(55),
    height: rem(55),
    flexGrow: 0,
    flexShrink: 0,
    marginRight: rem(13),
  },
  content: {
    ...LIBRE_FRANKLIN_REGULAR,
    fontSize: rem(14),
    color: NEUTRAL_COLOR_80,
    lineHeight: rem(19),
  },
  infos: {
    marginTop: rem(12),
  },
  authorName: {
    ...LIBRE_FRANKLIN_REGULAR,
    fontSize: rem(12),
    color: NEUTRAL_COLOR_50,
  },
  authorCompany: {
    ...LIBRE_FRANKLIN_SEMIBOLD,
    fontSize: rem(12),
    color: NEUTRAL_COLOR_90,
    marginLeft: rem(7),
  },
})

export default styles
