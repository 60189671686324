/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { em, rem } from 'polished'
import { colors, fonts, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_50, NEUTRAL_COLOR_90 } = colors
const { ARCHIVO_BOLD, ARCHIVO_SEMIBOLD } = fonts
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  h1: merge(
    {
      color: NEUTRAL_COLOR_90,
      ...ARCHIVO_BOLD,
      fontSize: rem(28),
      lineHeight: rem(32),
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(32),
      lineHeight: rem(38),
    }),
    media(LARGE_BREAKPOINT, {
      fontSize: rem(34),
      lineHeight: rem(40),
    }),
  ),
  h2: merge(
    {
      color: NEUTRAL_COLOR_90,
      ...ARCHIVO_SEMIBOLD,
      fontSize: rem(24),
      lineHeight: rem(32),
      marginBlockEnd: '0.25em',
    },
    media(MEDIUM_BREAKPOINT, {
      lineHeight: rem(36),
      fontSize: rem(28),
    }),
    media(LARGE_BREAKPOINT, {
      lineHeight: rem(38),
      fontSize: rem(30),
    }),
  ),
  h3: {
    color: NEUTRAL_COLOR_50,
    ...ARCHIVO_SEMIBOLD,
    fontSize: rem(18),
    lineHeight: em(24),
  },
  h4: {
    color: NEUTRAL_COLOR_90,
    ...ARCHIVO_BOLD,
    fontSize: rem(20),
    lineHeight: rem(28),
  },
  h5: merge(
    {
      color: NEUTRAL_COLOR_90,
      ...ARCHIVO_SEMIBOLD,
      fontSize: rem(22),
      lineHeight: rem(28),
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(23),
      lineHeight: rem(29),
    }),
    media(LARGE_BREAKPOINT, {
      fontSize: rem(23.5),
      lineHeight: rem(30),
    }),
  ),
})

export default styles
