/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import { colors } from './../../theme'
import Icon from './../Icon'
import styles from './styles'

const { BRAND_COLOR_50 } = colors

type Props = {
  children?: React$Node,
  className: string,
}

export default function PostLink(props: Props) {
  const { children, className } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <span className={css(styles.text)}>{children}</span>
      <Icon className={css(styles.icon)} name="link" color={BRAND_COLOR_50} />
    </div>
  )
}
