/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  title: merge(
    {
      marginTop: rem(4),
      marginBottom: rem(16),
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(20),
    }),
  ),
  link: merge(
    {
      fontSize: rem(24),
      lineHeight: rem(32),
      textDecoration: 'underline',
    },
    media(MEDIUM_BREAKPOINT, {
      lineHeight: rem(36),
      fontSize: rem(28),
    }),
  ),
  largeLink: merge(
    {
      fontSize: rem(28),
      lineHeight: rem(34),
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(36),
      lineHeight: rem(43),
    }),
  ),
})

export default styles
