/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge, hover } from 'glamor'
import { colors, fonts, breakpoints } from './../../theme'
import { rem } from 'polished'

const {
  BRAND_COLOR_50,
  BRAND_COLOR_60,
  SECONDARY_COLOR_50,
  SECONDARY_COLOR_60,
  NEUTRAL_COLOR_00,
  NEUTRAL_COLOR_80,
  NEUTRAL_COLOR_90,
} = colors
const { LIBRE_FRANKLIN_SEMIBOLD } = fonts
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  default: {
    cursor: 'pointer',
    paddingTop: rem(12),
    paddingRight: rem(24),
    paddingBottom: rem(12),
    paddingLeft: rem(24),
    borderRadius: rem(5),
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    textDecoration: 'none',
    ...LIBRE_FRANKLIN_SEMIBOLD,
    color: NEUTRAL_COLOR_00,
  },
  disabled: {
    cursor: 'default',
  },
  primaryLight: merge(
    {
      backgroundColor: BRAND_COLOR_50,
    },
    hover({
      backgroundColor: BRAND_COLOR_60,
      color: NEUTRAL_COLOR_00,
    }),
  ),
  disabledPrimaryLight: merge(
    {
      backgroundColor: BRAND_COLOR_50,
    },
    hover({
      backgroundColor: BRAND_COLOR_60,
      color: NEUTRAL_COLOR_00,
    }),
  ),
  primaryDark: merge(
    {
      backgroundColor: NEUTRAL_COLOR_80,
    },
    hover({
      backgroundColor: NEUTRAL_COLOR_90,
      color: NEUTRAL_COLOR_00,
    }),
  ),
  secondaryLight: merge(
    {
      backgroundColor: SECONDARY_COLOR_50,
    },
    hover({
      backgroundColor: SECONDARY_COLOR_60,
      color: NEUTRAL_COLOR_00,
    }),
  ),
  secondaryDark: merge(
    {
      backgroundColor: NEUTRAL_COLOR_80,
    },
    hover({
      backgroundColor: NEUTRAL_COLOR_90,
      color: NEUTRAL_COLOR_00,
    }),
  ),
  tiny: merge(
    {
      fontSize: rem(13),
      paddingTop: rem(6),
      paddingRight: rem(10),
      paddingBottom: rem(6),
      paddingLeft: rem(10),
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(14),
      paddingTop: rem(7),
      paddingRight: rem(12),
      paddingBottom: rem(7),
      paddingLeft: rem(12),
    }),
  ),
  small: {
    fontSize: rem(15.5),
    paddingTop: rem(10),
    paddingRight: rem(18),
    paddingBottom: rem(10),
    paddingLeft: rem(18),
  },
  normal: {
    fontSize: rem(16),
    paddingTop: rem(12),
    paddingRight: rem(24),
    paddingBottom: rem(12),
    paddingLeft: rem(24),
  },
  large: merge(
    {
      paddingTop: rem(13),
      paddingRight: rem(24),
      paddingBottom: rem(13),
      paddingLeft: rem(24),
      fontSize: rem(16),
    },
    media(LARGE_BREAKPOINT, {
      paddingRight: rem(24),
      paddingLeft: rem(24),
    }),
  ),
  huge: merge(
    {
      paddingTop: rem(13),
      paddingRight: rem(24),
      paddingBottom: rem(13),
      paddingLeft: rem(24),
      fontSize: rem(16),
    },
    media(LARGE_BREAKPOINT, {
      paddingTop: rem(15),
      paddingRight: rem(24),
      paddingBottom: rem(15.5),
      paddingLeft: rem(24),
    }),
  ),
  to: {
    textAlign: 'center',
  },
  href: {
    textAlign: 'center',
    width: 'auto',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
  },
})

export default styles
