/* @flow */

import * as React from 'react'
import Slick from 'react-slick'
import { merge, css } from 'glamor'
import Icon from './../Icon'
import Image from './../Image'
import { colors } from './../../theme'
import { goTo } from './utils'
import styles from './styles'

const { NEUTRAL_COLOR_50 } = colors

type Props = {
  className?: string,
  children: React$Node,
}

type State = {|
  index: number,
|}

type Direction = 'left' | 'right'

export default class Testimonials extends React.Component<Props, State> {
  slickContents: Slick = null
  slickImages: Slick = null

  state = { index: 0 }

  handleRefContent = (c: Slick) => {
    this.slickContents = c
  }

  handleRefImages = (c: Slick) => {
    this.slickImages = c
  }

  handleChangeTab = (index: number) => {
    this.setState({ index })
    if (this.slickContents) this.slickContents.slickGoTo(index)
    if (this.slickImages) this.slickImages.slickGoTo(index)
  }

  onSwipe = (reversedDirection: Direction) => {
    const { children } = this.props
    const { index } = this.state
    const childrenLength = React.Children.count(children)
    const direction = reversedDirection === 'left' ? 'right' : 'left'
    const nextIndex = goTo(index, childrenLength, direction)
    this.setState({ index: nextIndex })
    if (this.slickImages) this.slickImages.slickGoTo(nextIndex)
  }

  renderDots = () => {
    const { children } = this.props
    const activeIndex = this.state.index
    const slidesLength = React.Children.count(children)
    return (
      <div className={css(styles.dots)}>
        {Array.from({ length: slidesLength }).map((_, index) => (
          <div
            key={index}
            role="menuitem"
            tabIndex={activeIndex}
            onClick={() => this.handleChangeTab(index)}
            className={css(styles.dot, index === activeIndex && styles.active)}
          />
        ))}
      </div>
    )
  }

  renderArrow = (direction: Direction) => {
    const { children } = this.props
    const { index } = this.state
    const childrenLength = React.Children.count(children)
    return (
      <div
        role="menuitem"
        tabIndex={index}
        className={css(styles.arrow, styles[`${direction}Arrow`])}
        onClick={() => this.handleChangeTab(goTo(index, childrenLength, direction))}
      >
        <Icon name={direction} size={24} color={NEUTRAL_COLOR_50} />
      </div>
    )
  }

  render() {
    const { className, children } = this.props
    return (
      <div className={merge(css(styles.container), className)}>
        <div className={css(styles.wrapper)}>
          <div className={css(styles.images)}>
            <Slick arrows={false} dots={false} fade={true} swipe={false} ref={this.handleRefImages}>
              {React.Children.map(children, child => (
                <Image className={css(styles.image)} src={child.props.img} />
              ))}
            </Slick>
          </div>
          <div className={css(styles.content)}>
            {this.renderArrow('left')}
            <Slick
              ref={this.handleRefContent}
              infinite={true}
              onSwipe={this.onSwipe}
              arrows={false}
              speed={400}
              slidesToShow={1}
              slidesToScroll={1}
              dots={false}
            >
              {children}
            </Slick>
            {this.renderArrow('right')}
            {this.renderDots()}
          </div>
        </div>
      </div>
    )
  }
}
