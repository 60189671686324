/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover } from 'glamor'
import { rem } from 'polished'
import { fonts, colors } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR, LIBRE_FRANKLIN_SEMIBOLD } = fonts
const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_40, NEUTRAL_COLOR_70, BRAND_COLOR_50 } = colors

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: NEUTRAL_COLOR_10,
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: merge(
    {
      flex: 1,
      paddingLeft: 30,
      paddingRight: 30,
      cursor: 'pointer',
      outline: 'none',
      transition: 'color .1s ease .1s',
      fontSize: rem(15),
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_40,
      lineHeight: rem(18),
      textAlign: 'center',
    },
    hover({
      color: NEUTRAL_COLOR_70,
    }),
  ),
  activeItem: merge(
    {
      cursor: 'default',
      color: BRAND_COLOR_50,
      letterSpacing: -0.1,
      ...LIBRE_FRANKLIN_SEMIBOLD,
    },
    hover({
      color: BRAND_COLOR_50,
    }),
  ),
  indicator: {
    position: 'relative',
    marginTop: rem(-4),
    height: rem(2),
    width: rem(40),
    backgroundColor: BRAND_COLOR_50,
  },
})

export default styles
