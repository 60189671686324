/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover } from 'glamor'
import { rem } from 'polished'
import { fonts, colors } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR } = fonts
const { NEUTRAL_COLOR_00, NEUTRAL_COLOR_15 } = colors

const styles = StyleSheet.create({
  li: merge({
    marginTop: rem(11),
    marginBottom: rem(11),
    ...LIBRE_FRANKLIN_REGULAR,
    lineHeight: rem(25),
  }),
  link: merge(
    {
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_15,
      textDecoration: 'none',
      fontSize: rem(16),
      cursor: 'pointer',
    },
    hover({
      color: NEUTRAL_COLOR_00,
      textDecoration: 'underline',
    }),
  ),
})

export default styles
