/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { grid, colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_80 } = colors
const { X_SMALL_BREAKPOINT, SMALL_BREAKPOINT, MEDIUM_BREAKPOINT } = breakpoints
const { row, column } = grid

const styles = StyleSheet.create({
  container: merge(
    {
      paddingTop: rem(24),
      paddingBottom: rem(32),
      backgroundColor: NEUTRAL_COLOR_80,
    },
    media(X_SMALL_BREAKPOINT, {
      paddingTop: rem(32),
      paddingBottom: rem(36),
    }),
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(64),
      paddingBottom: rem(54),
    }),
  ),
  content: merge(row(), { justifyContent: 'flex-end' }),
  item: merge(
    column(1),
    media(SMALL_BREAKPOINT, column(6 / 12)),
    media(MEDIUM_BREAKPOINT, column(3 / 12)),
  ),
})

export default styles
