/* @flow */

import * as React from 'react'
import { colors } from './../../theme'
import { generateSvgAttrs } from './utils'

const { NEUTRAL_COLOR_80 } = colors

type Props = {
  name: string,
  size?: number,
  color?: string,
  className?: string,
}

export default class Icon extends React.Component<Props> {
  static defaultProps = {
    color: NEUTRAL_COLOR_80,
    size: 16,
  }

  render() {
    const { name, size, color, className } = this.props
    switch (name) {
      case 'arrow':
        return (
          <svg {...generateSvgAttrs({ width: 14, height: 8 }, size)} className={className}>
            <path
              d="M10 14a.997.997 0 0 1-.707-.293l-6-6a1 1 0 1 1 1.414-1.415L10 11.586l5.293-5.294a1 1 0 0 1 1.414 1.415l-6 6A.997.997 0 0 1 10 14"
              fillRule="evenodd"
              fill={color}
              transform="translate(-3 -6)"
            />
          </svg>
        )
      case 'menu': {
        return (
          <svg {...generateSvgAttrs({ width: 22, height: 18 }, size)} className={className}>
            <g fill={color} fillRule="evenodd">
              <path d="M21 2H1a1 1 0 1 1 0-2h20a1 1 0 1 1 0 2M21 10H1a1 1 0 1 1 0-2h20a1 1 0 1 1 0 2M21 18H1a1 1 0 1 1 0-2h20a1 1 0 1 1 0 2" />
            </g>
          </svg>
        )
      }
      case 'link': {
        return (
          <svg
            {...generateSvgAttrs({ width: 19, height: 20, scale: 1.125 }, size)}
            className={className}
          >
            <path
              d="M14.052 4.293a1 1 0 0 0-1.415 1.414L15.926 9H2a1 1 0 1 0 0 2h13.926l-3.289 3.293a1.001 1.001 0 0 0 1.415 1.414l4.994-5a1 1 0 0 0 0-1.414l-4.994-5z"
              fillRule="evenodd"
              fill={color}
            />
          </svg>
        )
      }
      case 'left': {
        return (
          <svg {...generateSvgAttrs({ width: 18, height: 24 }, size)} className={className}>
            <path
              d="M6 10c0-.266.105-.52.293-.707l6-6a1 1 0 1 1 1.415 1.414L8.414 10l5.294 5.293a1 1 0 0 1-1.415 1.414l-6-6A.997.997 0 0 1 6 10"
              fill={color}
              fillRule="evenodd"
            />
          </svg>
        )
      }
      case 'right': {
        return (
          <svg {...generateSvgAttrs({ width: 18, height: 24 }, size)} className={className}>
            <path
              d="M14 10c0 .265-.104.52-.292.707l-6 6a1 1 0 1 1-1.415-1.414L11.587 10 6.293 4.707a1.002 1.002 0 0 1 0-1.415 1.002 1.002 0 0 1 1.415 0l6 6.001A.996.996 0 0 1 14 10"
              fill={color}
              fillRule="evenodd"
            />
          </svg>
        )
      }
      case 'facebook': {
        return (
          <svg {...generateSvgAttrs({ width: 9, height: 18 }, size)} className={className}>
            <path
              d="M8.786 5.786H5.794v-1.88c0-.707.487-.872.831-.872S9 3 9 3V0H5.87C2.64 0 1.866 2.234 1.866 3.716v2.07H0v3.2L1.957 9v9H5.87V9l2.573-.014.343-3.2z"
              fillRule="evenodd"
              fill={color}
            />
          </svg>
        )
      }
      case 'twitter': {
        return (
          <svg {...generateSvgAttrs({ width: 18, height: 15 }, size)} className={className}>
            <path
              d="M16.099 3.752c.207 2.502-.8 4.634-1.135 5.398-.335.763-1.986 4.133-6.656 5.477C3.638 15.969 0 13.257 0 13.257c3.276.422 5.34-1.553 5.34-1.553-2.786-.263-3.327-2.607-3.327-2.607 1.186.185 1.496-.13 1.496-.13C.49 8.044.696 5.278.696 5.278c1.238.606 1.574.37 1.574.37C-.44 3.41 1.212.671 1.212.671c3.536 4.213 7.56 3.95 7.56 3.95-.103-.914-.103-3.354 2.27-4.336 2.383-.986 4.128.894 4.128.894.955-.06 2.59-.962 2.59-.962-.188 1.036-1.875 2.148-1.875 2.148C16.735 2.305 18 1.76 18 1.76c-.18.508-1.901 1.991-1.901 1.991"
              fillRule="evenodd"
              fill={color}
            />
          </svg>
        )
      }
      case 'instagram': {
        return (
          <svg {...generateSvgAttrs({ width: 20, height: 20 }, size)} className={className}>
            <g fillRule="evenodd" fill={color}>
              <path d="M9.92 13.16c-1.8 0-3.28-1.48-3.28-3.28 0-1.8 1.48-3.28 3.28-3.28 1.8 0 3.28 1.48 3.28 3.28 0 1.8-1.48 3.28-3.28 3.28m0-8.4c-2.8 0-5.12 2.28-5.12 5.12C4.8 12.72 7.08 15 9.92 15c2.84 0 5.12-2.32 5.12-5.12 0-2.8-2.32-5.12-5.12-5.12M15.24 3.48a1.16 1.16 0 1 1 0 2.32 1.16 1.16 0 0 1 0-2.32" />
              <path d="M18 14.04c0 1.24-.36 2.24-1.08 2.92C16.2 17.64 15.2 18 14 18H5.84c-1.2 0-2.2-.36-2.92-1.04-.72-.72-1.08-1.72-1.08-2.96V5.8c0-1.2.36-2.2 1.08-2.92.68-.68 1.72-1.04 2.92-1.04h8.24c1.2 0 2.2.36 2.92 1.08.68.72 1.08 1.72 1.08 2.88v8.24H18zm.24-12.4C17.2.56 15.72 0 14.04 0H5.8C2.32 0 0 2.32 0 5.8V14c0 1.72.56 3.2 1.68 4.28 1.08 1.04 2.52 1.56 4.16 1.56H14c1.72 0 3.16-.56 4.2-1.56 1.08-1.04 1.64-2.52 1.64-4.24V5.8c0-1.68-.56-3.12-1.6-4.16z" />
            </g>
          </svg>
        )
      }
      case 'check': {
        return (
          <svg {...generateSvgAttrs({ width: 18, height: 18 }, size)} className={className}>
            <g transform="translate(1 1)" stroke="#1DBB7A" fill="none" fillRule="evenodd">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.188 8.524L7.22 11.05l3.591-4.461"
              />
              <ellipse cx={8} cy={8.28} rx={7.875} ry={8.151} />
            </g>
          </svg>
        )
      }
      case 'phone': {
        return (
          <svg
            {...generateSvgAttrs({ width: 24, height: 24 }, size)}
            className={className}
            fill="none"
            stroke={color}
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
          </svg>
        )
      }
      case 'linkedin': {
        return (
          <svg
            {...generateSvgAttrs({ width: 430.117, height: 430.118, scale: 25 }, size)}
            className={className}
          >
            <path
              d="M398.355 0H31.782C14.229 0 .002 13.793.002 30.817v368.471c0 17.025 14.232 30.83 31.78 30.83h366.573c17.549 0 31.76-13.814 31.76-30.83V30.817C430.115 13.798 415.904 0 398.355 0zM130.4 360.038H65.413V165.845H130.4v194.193zM97.913 139.315h-.437c-21.793 0-35.92-14.904-35.92-33.563 0-19.035 14.542-33.535 36.767-33.535 22.227 0 35.899 14.496 36.331 33.535 0 18.663-14.099 33.563-36.741 33.563zm266.746 220.723h-64.966v-103.9c0-26.107-9.413-43.921-32.907-43.921-17.973 0-28.642 12.018-33.327 23.621-1.736 4.144-2.166 9.94-2.166 15.728v108.468h-64.954s.85-175.979 0-194.192h64.964v27.531c8.624-13.229 24.035-32.1 58.534-32.1 42.76 0 74.822 27.739 74.822 87.414v111.351zM230.883 193.99c.111-.182.266-.401.42-.614v.614h-.42z"
              fill={color}
            />
          </svg>
        )
      }
      default:
        return null
    }
  }
}
