/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints, grid, fonts } from './../../theme'

const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_90 } = colors
const { MEDIUM_BREAKPOINT } = breakpoints
const { ARCHIVO_SEMIBOLD } = fonts
const { DEFAULT_GUTTER } = grid

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
      paddingTop: rem(132),
      paddingBottom: rem(164),
    }),
    before(
      merge({
        content: ' ',
        display: 'block',
        position: 'absolute',
        left: rem(-DEFAULT_GUTTER),
        width: `calc(100% + ${rem(DEFAULT_GUTTER * 2)})`,
        height: '100%',
        bottom: 0,
        zIndex: -2,
        backgroundColor: NEUTRAL_COLOR_10,
      }),
    ),
    media(
      MEDIUM_BREAKPOINT,
      before({
        width: '114%',
        left: '-7%',
      }),
    ),
  ),
  figure: merge(
    {},
    media(MEDIUM_BREAKPOINT, {
      position: 'absolute',
      zIndex: -1,
      bottom: 0,
      maxHeight: '100%',
    }),
  ),
  startFigure: merge(
    {
      marginTop: rem(24),
      left: 0,
      maxHeight: rem(300),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(0),
      left: rem(-150),
    }),
  ),
  endFigure: merge(
    {
      display: 'none',
      right: 0,
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'block',
      right: rem(-100),
    }),
  ),
  content: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    media(MEDIUM_BREAKPOINT, {
      width: 'auto',
    }),
  ),
  title: merge(
    {
      marginTop: rem(48),
      marginBottom: 0,
      maxWidth: rem(400),
      ...ARCHIVO_SEMIBOLD,
      color: NEUTRAL_COLOR_90,
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: 0,
    }),
  ),
  form: merge(
    {
      marginTop: rem(24),
      width: '100%',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(32),
      maxWidth: rem(775),
    }),
  ),
})

export default styles
