/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  handleOpenDropdown: () => void,
  handleCloseDropdown: () => void,
  isOpen: boolean,
  activeIndex: number,
}

export default class NavBarDropdown extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  onMouseEnter = (event: SyntheticEvent<HTMLDivElement>) => {
    const { handleOpenDropdown } = this.props
    handleOpenDropdown()
  }

  onMouseLeave = (event: SyntheticEvent<HTMLDivElement>) => {
    const { handleCloseDropdown } = this.props
    handleCloseDropdown()
  }

  render() {
    const { children, isOpen, activeIndex } = this.props

    return (
      <>
        {React.Children.map(children, child =>
          <div className={css(styles.container, (isOpen && activeIndex == child.key.charAt(1)) && styles.open, activeIndex === 0 ? styles.products : styles.business)}>
            {React.cloneElement(child, {
              onMouseEnter: this.onMouseEnter,
              onMouseLeave: this.onMouseLeave,
            })}
          </div>
        )}
      </>
    )
  }
}
