import * as React from 'react'
import { css } from 'glamor'

import styles from './styles'

type Props = {
  link: string,
  image: string,
  target?: string,
}

export default function(props: Props) {
  const { link, image, target } = props

  return (
    <a href={link} target={target} className={css(styles.global)}>
      <img src={image} alt="" className={css(styles.img)} />
    </a>
  )
}
