/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  size?: 'normal' | 'large',
  className?: string,
}

export default class Wrapper extends React.Component<Props> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { children, size, className } = this.props
    return (
      <div className={merge(css(styles.main, size === 'large' && styles.large), className)}>
        {children}
      </div>
    )
  }
}
