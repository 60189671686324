/* @flow */

import { StyleSheet } from 'glamor/aphrodite'

const styles = StyleSheet.create({
  main: {
    display: 'inline-block',
  },
})

export default styles
