/* @flow */

import { rem, rgba } from 'polished'
import { merge, before } from 'glamor'
import { StyleSheet } from 'glamor/aphrodite'
import { colors } from './../../theme'

const { NEUTRAL_COLOR_00, NEUTRAL_COLOR_90 } = colors

const styles = StyleSheet.create({
  container: merge(
    {
      position: 'absolute',
      marginTop: rem(16),
      marginLeft: rem(48),
      borderRadius: rem(4),
      paddingTop: rem(24),
      paddingRight: rem(24),
      paddingBottom: rem(10),
      paddingLeft: rem(24),
      maxWidth: rem(235),
      backgroundColor: NEUTRAL_COLOR_00,
      boxShadow: `0px 1px 20px ${rgba(NEUTRAL_COLOR_90, 0.125)}`,
    },
    before({
      content: ' ',
      display: 'block',
      position: 'absolute',
      top: rem(-7),
      left: '50%',
      marginLeft: rem(-7),
      width: rem(13),
      height: rem(13),
      backgroundColor: NEUTRAL_COLOR_00,
      transform: 'rotate(45deg)',
    }),
  ),
})

export default styles
