/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Link from 'gatsby-link'
import styles from './styles'

type Props = {
  children: string,
  onClick?: Function,
  className?: string,
  type?: 'primary' | 'secondary',
  to?: string,
  appearance?: 'light' | 'dark',
  size?: 'tiny' | 'small' | 'normal' | 'large' | 'huge',
  disabled?: boolean,
  state?: Object,
  target?: string,
  href?: string,
  download?: string,
}

export default class Button extends React.Component<Props> {
  static defaultProps = {
    type: 'primary',
    appearance: 'light',
    size: 'normal',
  }

  render() {
    const {
      children,
      onClick,
      className,
      type,
      to,
      appearance,
      size,
      disabled,
      state,
      target,
      href,
      download,
      id,
    } = this.props
    let elTag = ''
    if (href) elTag = 'a'
    else if (to) elTag = Link
    else elTag = 'button'
    return React.createElement(
      elTag, // $FlowFixMe
      Object.assign({}, onClick && { onClick }, to && { to, state }, {
        id,
        target,
        href,
        download,
        className: merge(
          css(
            styles.default,
            disabled && styles.disabled,
            type === 'primary' && appearance === 'light' && styles.primaryLight,
            type === 'primary' && appearance === 'light' && disabled && styles.disabledPrimaryLight,
            type === 'primary' && appearance === 'dark' && styles.primaryDark,
            type === 'secondary' && appearance === 'light' && styles.secondaryLight,
            type === 'secondary' && appearance === 'dark' && styles.secondaryDark,
            size === 'tiny' && styles.tiny,
            size === 'small' && styles.small,
            size === 'large' && styles.large,
            size === 'huge' && styles.huge,
            to && styles.to,
            href && styles.href,
          ),
          className,
        ),
        disabled,
      }),
      children,
    )
  }
}
