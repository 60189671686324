/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  active?: boolean,
  className?: string,
  handleOpenDropdown?: () => void,
  handleCloseDropdown?: () => void,
}

type State = {|
  isHovered: boolean,
|}

export default class NavBarItem extends React.Component<Props, State> {
  state = { isHovered: false }

  handleHover = () => {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered,
    }))
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.props.active !== nextProps.active || this.state.isHovered !== nextState.isHovered
  }

  render() {
    const { children, className, handleOpenDropdown, handleCloseDropdown, active } = this.props
    const { isHovered } = this.state
    if (!children) return null
    const [label, dropdown] = React.Children.toArray(children)
    return (
      <li
        className={merge(css(styles.container), className)}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        {React.cloneElement(label, {
          hasDropdown: !!dropdown,
          active: active || isHovered,
          className: css(styles.label, !dropdown && styles.plainLabel),
          handleOpenDropdown,
          handleCloseDropdown,
        })}
        {dropdown && (
          <div className={css(styles.dropdown)}>
            {React.Children.map(dropdown.props.children, child => child)}
          </div>
        )}
      </li>
    )
  }
}
