/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, parent, media } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_40, SECONDARY_COLOR_50 } = colors
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: {
    textDecoration: 'none',
  },
  media: merge(
    {
      width: '100%',
      height: rem(175),
      marginBottom: rem(14),
      backgroundColor: NEUTRAL_COLOR_40,
      objectFit: 'cover',
    },
    media(MEDIUM_BREAKPOINT, {
      height: rem(200),
      marginBottom: rem(16),
    }),
  ),
  meta: merge(
    {
      color: NEUTRAL_COLOR_40,
      fontSize: rem(13),
    },
    media(SMALL_BREAKPOINT, {
      fontSize: rem(14),
    }),
  ),
  title: merge(
    {
      marginTop: rem(4),
      marginBottom: rem(-2),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(1),
      marginBottom: rem(0),
    }),
  ),
  paragraph: merge(
    {
      marginTop: rem(6),
      marginBottom: rem(10),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(16),
      marginBottom: rem(10),
    }),
  ),
  link: merge(
    {
      opacity: 1,
    },
    media(MEDIUM_BREAKPOINT, {
      opacity: 0,
      transition: 'opacity 200ms ease',
    }),
    parent(':hover >', {
      opacity: 1,
    }),
  ),
  categorie: {
    color: SECONDARY_COLOR_50,
  },
})

export default styles
