/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  children: React$Node,
}

export default function CalloutCardLabel(props: Props) {
  const { children, className } = props
  return (
    <span className={merge(css(styles.label), className)}>
      {React.Children.map(children, child => {
        if (typeof child === 'string') return child
        return React.cloneElement(child, {
          className: css(styles.link),
          disableIcon: true,
        })
      })}
    </span>
  )
}
