/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge } from 'glamor'
import { rem } from 'polished'
import { fonts, colors } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR } = fonts
const { NEUTRAL_COLOR_65, NEUTRAL_COLOR_90 } = colors

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  main: merge(
    {
      fontSize: rem(16),
      ...LIBRE_FRANKLIN_REGULAR,
      color: NEUTRAL_COLOR_90,
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      fontSize: rem(17),
      color: NEUTRAL_COLOR_65,
    }),
  ),
  active: merge(
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      color: NEUTRAL_COLOR_90,
    }),
  ),
})

export default styles
