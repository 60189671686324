/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { fonts, colors, breakpoints } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR } = fonts
const { NEUTRAL_COLOR_50, NEUTRAL_COLOR_80 } = colors
const { SMALL_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  main: {
    ...LIBRE_FRANKLIN_REGULAR,
    color: NEUTRAL_COLOR_50,
  },
  small: merge(
    {
      fontSize: rem(13.5),
      lineHeight: rem(23),
      color: NEUTRAL_COLOR_80,
    },
    media(SMALL_BREAKPOINT, {
      fontSize: rem(14),
      lineHeight: rem(24),
    }),
  ),
  normal: merge(
    {
      fontSize: rem(15.5),
      lineHeight: rem(26),
    },
    media(SMALL_BREAKPOINT, {
      fontSize: rem(16),
      lineHeight: rem(27),
    }),
  ),
  medium: merge(
    {
      fontSize: rem(17),
      lineHeight: rem(26),
    },
    media(SMALL_BREAKPOINT, {
      fontSize: rem(18),
      lineHeight: rem(27),
    }),
  ),
  large: merge(
    {
      fontSize: rem(19),
      lineHeight: rem(28),
    },
    media(SMALL_BREAKPOINT, {
      fontSize: rem(20),
      lineHeight: rem(29),
    }),
  ),
})

export default styles
