/* @flow */

import React from 'react'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  children: string,
}

export default function Badge(props: Props) {
  const { children } = props
  return <span className={css(styles.main)}>{children}</span>
}
