/* @flow */

import { css } from 'glamor'
import 'glamor/reset'

css.global('button', {
  padding: 0,
  margin: 0,
  border: 0,
  background: 'none',
})

css.global('svg', {
  display: 'block',
})

css.global('ul', {
  listStyle: 'none',
  paddingLeft: 0,
})

css.global('a', {
  textDecoration: 'none',
})

css.global('input', {
  appearance: 'none',
  boxSizing: 'border-box',
})
