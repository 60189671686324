/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  list: merge(
    {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    }),
  ),
  itemWithOffset: merge(
    media(MEDIUM_BREAKPOINT, {
      marginLeft: 0,
    }),
    media(LARGE_BREAKPOINT, {
      marginLeft: 4,
    }),
    media(HUGE_BREAKPOINT, {
      marginLeft: 8,
    }),
  ),
  dropdown: merge(
    {
      display: 'none',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'block',
    }),
  ),
})

export default styles
