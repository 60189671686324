/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { placeholder, merge, focus, hover, media } from 'glamor'
import { rem, padding } from 'polished'
import { fonts, colors, breakpoints } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR, LIBRE_FRANKLIN_SEMIBOLD } = fonts
const { NEUTRAL_COLOR_15, NEUTRAL_COLOR_25, NEUTRAL_COLOR_30, NEUTRAL_COLOR_40, DANGER_COLOR_50 } = colors
const { SMALL_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  label: {
    display: 'block',
    marginBottom: rem(5),
    ...LIBRE_FRANKLIN_SEMIBOLD,
    color: NEUTRAL_COLOR_40,
    fontSize: rem(12),
  },
  input: merge(
    {
      ...LIBRE_FRANKLIN_REGULAR,
      fontSize: rem(15),
      ...padding(rem(12), rem(16), rem(11)),
      boxSizing: 'border-box',
      borderRadius: rem(5),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: NEUTRAL_COLOR_15,
      outline: 'none',
      width: '100%',
    },
    media(SMALL_BREAKPOINT, {
      ...padding(rem(14), rem(16), rem(13)),
    }),
    placeholder({
      color: NEUTRAL_COLOR_30,
    }),
    hover({
      borderColor: NEUTRAL_COLOR_25,
    }),
    focus({
      borderColor: NEUTRAL_COLOR_30,
    }),
  ),
  normalInput: merge(
    {
      fontSize: rem(15),
      ...padding(rem(12), rem(16), rem(11)),
    },
    media(SMALL_BREAKPOINT, {
      ...padding(rem(13), rem(16), rem(12)),
    }),
  ),
  largeInput: merge(
    {
      fontSize: rem(15),
      ...padding(rem(12), rem(17), rem(11)),
    },
    media(SMALL_BREAKPOINT, {
      ...padding(rem(14), rem(17.5), rem(13)),
    }),
    media(LARGE_BREAKPOINT, {
      fontSize: rem(16),
      ...padding(rem(16), rem(20), rem(15)),
    }),
  ),
  error: {
    display: 'block',
    marginTop: rem(6),
    marginBottom: rem(6),
    ...LIBRE_FRANKLIN_REGULAR,
    color: DANGER_COLOR_50,
    fontSize: rem(11),
  },
})

export default styles
