import url from 'url'
import Cookies from 'js-cookie'

const allowedUtms = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'referer',
  'gclid',
]

// Disclaimer:
// The following code inspired by
// https://github.com/deepaksalve/url-utm-params
// Confirmed no possible Regex DDOS Attack issues
// Using http://redos-checker.surge.sh/
export function utm(link) {
  const parsed = url.parse(unescape(link))
  const query = parsed.query || ''
  const utmRegx = new RegExp('((utm_[^=]+)=([^\\&]+))', 'gi')
  const matched = query.match(utmRegx) || []
  const params =
    matched.reduce((object, match) => {
      const [key, value] = match.split('=')
      object[key] = value
      return object
    }, {}) || {}

  return Object.keys(params)
    .filter(key => allowedUtms.includes(key))
    .reduce((object, key) => {
      object[key] = params[key]
      return object
    }, {})
}

export function saveUtmsInCookies(link) {
  const utms = utm(link)

  Object.keys(utms).map(key => {
    Cookies.set(key, utms[key], { secure: true })
  })
}

export function findUtmsInCookies() {
  return allowedUtms.reduce((object, key) => {
    const value = Cookies.get(key)
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!value) {
      object[key] = value
    }
    return object
  }, {})
}
