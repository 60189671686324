/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node[],
  className?: string,
  title?: string,
}

export default function List(props: Props) {
  const { children, title, className } = props
  return (
    <div className={className}>
      {title && <span className={css(styles.title)}>{title}</span>}
      <ul className={css(styles.ul, !title && styles.ulWithoutTitle)}>{children}</ul>
    </div>
  )
}
