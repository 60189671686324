/* @flow */
/* eslint no-mixed-operators: 0 */

type SvgAttrs = {
  width: number,
  height: number,
  viewBox: string,
}

export const generateSvgAttrs = (
  params: { width: number, height: number, scale?: number },
  size?: number = 16,
): SvgAttrs => {
  const { width, height, scale = 1 } = params
  return {
    width: (width / scale) * (size / 16),
    height: (height / scale) * (size / 16),
    viewBox: `0 0 ${width} ${height}`,
  }
}
