/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors, breakpoints, utils } from './../../theme'
import { merge, media } from 'glamor'

const { NEUTRAL_COLOR_00, NEUTRAL_COLOR_20, SECONDARY_COLOR_50, BRAND_COLOR_50 } = colors
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT } = breakpoints
const { reverseToMaxBreakpoint } = utils

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'row',
      marginTop: rem(20),
      borderRadius: rem(4),
      backgroundColor: NEUTRAL_COLOR_00,
    },
    media(reverseToMaxBreakpoint(SMALL_BREAKPOINT), {
      flexDirection: 'column',
    }),
    media(reverseToMaxBreakpoint(MEDIUM_BREAKPOINT), {
      flexDirection: 'column',
      display: 'inline-flex',
    }),
  ),
  contentColumn: merge(
    {
      flexBasis: '55%',
      padding: rem(53),
      border: rem(1) + ' solid ' + NEUTRAL_COLOR_20,
    },
    media(reverseToMaxBreakpoint(MEDIUM_BREAKPOINT), {
      padding: rem(25),
    }),
  ),
  primaryTitle: {
    color: BRAND_COLOR_50,
  },
  secondaryTitle: {
    color: SECONDARY_COLOR_50,
  },
  excerpt: {
    margin: 0,
  },
  link: {
    marginTop: rem(28),
  },
  mediaColumn: {
    flexBasis: '45%',
  },
  media: merge(
    {
      width: '100%',
      height: '100%',
    },
    media(reverseToMaxBreakpoint(MEDIUM_BREAKPOINT), {}),
  ),
})

export default styles
