/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Title from './../Title'
import styles from './styles'

type Size = 'small' | 'normal' | 'large' | 'huge'

type Props = {
  size?: Size,
  className?: string,
  children: React$Node,
}

export default class CalloutCardtitle extends React.Component<Props> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { children, className, size } = this.props
    return (
      <Title className={merge(css(styles.title), className)} level={size === 'normal' ? 2 : 1}>
        {React.Children.map(children, child => {
          if (typeof child === 'string') return child
          return React.cloneElement(child, {
            className: css(styles.link, size === 'large' && styles.largeLink),
            disableIcon: true,
          })
        })}
      </Title>
    )
  }
}
