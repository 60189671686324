/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    marginRight: rem(24),
    width: rem(80),
    height: rem(80),
    borderRadius: rem(40),
  },
})

export default styles
