/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { merge, media } from 'glamor'
import { colors, breakpoints, grid } from './../../theme'

const { NEUTRAL_COLOR_50, NEUTRAL_COLOR_90 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { row, column } = grid

const styles = StyleSheet.create({
  root: merge(
    {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: rem(18),
      paddingBottom: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      display: 'block',
      paddingTop: rem(24),
      paddingBottom: rem(32),
    }),
    media(LARGE_BREAKPOINT, {
      display: 'block',
      paddingTop: rem(52),
      paddingBottom: rem(44),
    }),
  ),
  wrapper: merge(
    {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      ...row(),
    }),
  ),
  content: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 2),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
    }),
  ),
  figure: merge(
    {
      maxWidth: '100%',
    },
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 2),
      height: 400,
    }),
    media(LARGE_BREAKPOINT, {
      ...column(6 / 12),
      top: rem(24),
    }),
    media(HUGE_BREAKPOINT, {
      top: rem(32),
      transform: 'scale(1.25)',
      right: rem(-82),
    }),
  ),
  title: merge(
    {
      color: NEUTRAL_COLOR_90,
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      textAlign: 'left',
    }),
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(400),
    }),
  ),
  description: merge(
    {
      marginBottom: rem(24),
      color: NEUTRAL_COLOR_50,
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(18),
      textAlign: 'left',
    }),
  ),
})

export default styles
