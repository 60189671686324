/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors } from './../../theme'

const { NEUTRAL_COLOR_80 } = colors

const styles = StyleSheet.create({
  container: {
    paddingTop: rem(6),
    paddingBottom: rem(6),
    backgroundColor: NEUTRAL_COLOR_80,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default styles
