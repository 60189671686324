/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  children: React$Node,
}

export default function FooterGroup(props: Props) {
  const { className, children } = props
  return (
    <div className={merge(css(styles.container), className)}>
      {React.Children.map(children, (child, index) => {
        if (index % 2) return child
        return React.cloneElement(child, {
          className: merge(css(styles.title), child.props.className),
        })
      })}
    </div>
  )
}
