/* @flow */

import { merge, media, before, after, hover } from 'glamor'
import { rem, rgba } from 'polished'
import { StyleSheet } from 'glamor/aphrodite'
import { colors, grid, breakpoints, utils } from './../../theme'

const { NEUTRAL_COLOR_00, SECONDARY_COLOR_50, NEUTRAL_COLOR_90 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { DEFAULT_GUTTER } = grid
const { generateImagePath } = utils

const styles = StyleSheet.create({
  container: merge(
    {
      position: 'relative',
      borderRadius: 4,
      backgroundColor: SECONDARY_COLOR_50,
      transition: 'box-shadow ease .2s, transform ease .2s',
      zIndex: 0,
    },
    hover({
      boxShadow: `0 2px 30px ${rgba(NEUTRAL_COLOR_90, 0.175)}`,
      transform: 'translateY(-3px)',
    }),
  ),
  wrapper: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: DEFAULT_GUTTER * 1,
      zIndex: 2,
    },
    media(MEDIUM_BREAKPOINT, {
      padding: DEFAULT_GUTTER * 1.5,
    }),
    media(LARGE_BREAKPOINT, {
      padding: DEFAULT_GUTTER * 2,
    }),
  ),
  content: merge(
    {
      marginTop: rem(0),
      marginBottom: rem(2),
      maxWidth: rem(242),
      color: NEUTRAL_COLOR_00,
    },
    media(MEDIUM_BREAKPOINT, {
      maxWidth: '100%',
    }),
  ),
  title: {
    color: NEUTRAL_COLOR_00,
  },
  button: merge(
    {
      marginTop: rem(16),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(18),
    }),
  ),
  before: before(
    merge(
      {
        display: 'block',
        position: 'absolute',
        width: '75%',
        height: '75%',
        bottom: 0,
        left: 0,
        zIndex: -1,
        backgroundSize: 'contain',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat',
      },
      media(LARGE_BREAKPOINT, {
        content: ' ',
        width: '100%',
        height: '100%',
      }),
    ),
  ),
  after: after(
    merge(
      {
        content: ' ',
        display: 'block',
        position: 'absolute',
        width: '75%',
        height: '75%',
        bottom: 0,
        right: 0,
        zIndex: -1,
        backgroundSize: 'contain',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
      },
      media(LARGE_BREAKPOINT, {
        width: '100%',
        height: '100%',
      }),
    ),
  ),
  dashboard: merge(
    before({
      backgroundImage: `url(${generateImagePath('abstractpattern_dashboard-top.png')})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath('abstractpattern_dashboard-bottom.png')})`,
    }),
  ),
  fidelity: merge(
    before({
      backgroundImage: `url(${generateImagePath('abstractpattern_fidelity-top.png')})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath('abstractpattern_fidelity-bottom.png')})`,
    }),
  ),
  business: after({
    backgroundImage: `url(${generateImagePath('abstractpattern_business-bottom.png')})`,
  }),
  multiboutique: after({
    backgroundImage: `url(${generateImagePath(
      'abstractpattern_multiboutique_bottom_desktop.png',
    )})`,
  }),
  potico: after({
    backgroundImage: `url(${generateImagePath('abstractpattern_potico_bottom_desktop.png')})`,
  }),
})

export default styles
