/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors } from './../../theme'

const { NEUTRAL_COLOR_00 } = colors

const styles = StyleSheet.create({
  title: {
    marginTop: rem(32),
    marginLeft: 0,
    marginBottom: rem(-10),
    marginRight: rem(0),
    color: NEUTRAL_COLOR_00,
  },
})

export default styles
