/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 32,
    paddingRight: 32,
  },
  inline: merge(
    media(LARGE_BREAKPOINT, {
      marginTop: rem(6),
    }),
  ),
  name: {
    marginRight: rem(6),
    textAlign: 'center',
  },
  content: merge(
    {
      marginBottom: rem(8),
      textAlign: 'center',
      maxWidth: rem(350),
    },
    media(LARGE_BREAKPOINT, {
      maxWidth: rem(400),
    }),
  ),
})

export default styles
