/* @flow */

import { rem } from 'polished'
import { media, merge } from 'glamor'
import { StyleSheet } from 'glamor/aphrodite'

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'none',
      position: 'absolute',
      opacity: 0,
      transform: 'rotateX(-15deg)',
      transition: 'ease .2s opacity, ease .5s transform',
      pointerEvents: 'none',
      cursor: 'default',
      height: rem(200),
      width: rem(300),
      zIndex: 2,
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'block',
    }),
  ),
  open: {
    opacity: 1,
    pointerEvents: 'auto',
    transform: 'rotateX(0deg)',
  },
  products: {
    left: rem(-80),
  },
  business: {
    left: rem(60),
  },
})

export default styles
