/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import { colors } from './../../theme'
import styles from './styles'

const { BRAND_COLOR_50 } = colors

type Props = {
  className?: string,
  color?: string,
  width?: number,
  height?: number,
}

export default class Logo extends React.Component<Props> {
  static defaultProps = {
    color: BRAND_COLOR_50,
    width: 66,
    height: 16,
  }

  render() {
    const { className, color, width, height } = this.props
    return (
      <svg
        className={css(merge(styles.defaults, className))}
        width={width}
        height={height}
        viewBox="0 0 58 15"
      >
        <g fill={color} fillRule="evenodd">
          <path d="M53.097 10.393c-.445.642-1.14.964-2.083.964-.97 0-1.68-.322-2.13-.964-.45-.642-.673-1.62-.673-2.93s.224-2.287.674-2.924c.45-.64 1.16-.96 2.13-.96.944 0 1.638.32 2.083.96.445.636.668 1.612.668 2.922s-.223 2.29-.668 2.93M57.49 4.61c-.34-.84-.816-1.553-1.43-2.14-.613-.588-1.346-1.04-2.202-1.354C53.002.8 52.054.643 51.014.643c-1.05 0-2.004.158-2.865.473-.86.315-1.6.766-2.222 1.353-.622.587-1.103 1.3-1.443 2.14-.34.836-.51 1.78-.51 2.828 0 1.054.17 2.006.51 2.853.34.848.82 1.568 1.443 2.158.62.593 1.363 1.048 2.22 1.368.862.318 1.817.478 2.866.478 1.04 0 1.988-.16 2.844-.478.856-.32 1.59-.775 2.202-1.368.614-.59 1.09-1.31 1.43-2.157.34-.846.51-1.798.51-2.852 0-1.048-.17-1.992-.51-2.83M27.784 2.97c0-1.172-.954-2.122-2.132-2.122h-1.97V14.09h4.102V2.97zM18.648.847c-.54.07-1.03.47-1.177.98l-.01.01-1.382 5.637c-.124.462-.234.905-.33 1.33-.098.425-.18.85-.252 1.276-.106-.434-.223-.864-.35-1.29-.128-.425-.258-.864-.39-1.316l-1.58-5.692-.006-.005c-.167-.52-.636-.905-1.202-.942H9.996c-.565.037-1.036.423-1.2.942l-.008.005-1.58 5.692c-.132.452-.26.89-.388 1.316-.13.426-.245.856-.352 1.29-.07-.425-.153-.85-.25-1.276-.098-.425-.208-.868-.33-1.33L4.503 1.837l-.01-.01c-.15-.51-.636-.91-1.177-.98H.225l4.26 13.243H7.35c.62 0 1.14-.414 1.304-.98l1.862-6.286c.098-.297.184-.59.26-.88.073-.29.146-.584.208-.882.062.298.13.592.206.88.076.29.16.585.26.882l1.86 6.287c.163.566.685.98 1.303.98h2.866L21.74.847h-3.092zM37.985.847L37.46.835h-7.4V14.09h2.962c.712 0 1.29-.577 1.29-1.287l-.006-6.307c-.126-.042-.216-.157-.216-.294v-.58c0-.133.09-.248.21-.29V4.95c0-.304.25-.55.554-.55h2.26c.305 0 .56.246.56.55v.379c.124.04.213.156.213.292v.58c0 .137-.09.252-.217.294 0 2.126-.006 5.562-.007 6.293l-.002.012.003.012v.102l.01.01c.066.653.61 1.164 1.28 1.164h2.968V4.766c0-2.164-1.76-3.92-3.935-3.92" />
        </g>
      </svg>
    )
  }
}
