/* @flow */

import React from 'react'
import { css } from 'glamor'
import Wrapper from './../Wrapper'
import styles from './styles'

type Props = {
  children: React$Node,
}

export default function Banner(props: Props) {
  const { children } = props
  return (
    <div className={css(styles.container)}>
      <Wrapper className={css(styles.wrapper)}>{children}</Wrapper>
    </div>
  )
}
