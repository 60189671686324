/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'

const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: rem(0),
    listStyleType: 'none',
    marginTop: rem(28),
    marginBottom: 0,
  },
})

export default styles
