/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  className?: string,
}

export default function Inline(props: Props) {
  const { children, className } = props
  return <div className={merge(css(styles.main), className)}>{children}</div>
}
