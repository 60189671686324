/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge } from 'glamor'
import { rem } from 'polished'

const styles = StyleSheet.create({
  main: merge({
    marginTop: rem(24),
    marginLeft: 0,
    marginBottom: rem(24),
    width: '75%',
    height: rem(1.5),
  }),
})

export default styles
