/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints, colors } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { NEUTRAL_COLOR_00 } = colors

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    paddingTop: rem(70),
    zIndex: 0,
  },
  bannerSpan: {
    color: NEUTRAL_COLOR_00,
    marginRight: rem(12),
  },
  social: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: rem(24),
    width: rem(100),
  },
  link: merge(
    {
      opacity: 0.7,
    },
    hover({
      opacity: 1,
    }),
  ),
  loginButton: {
    textDecoration: 'none',
  },
  twitter: {
    position: 'relative',
    top: rem(1),
  },
  inbound: merge(
    {
      marginBottom: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: rem(48),
    }),
    media(LARGE_BREAKPOINT, {
      marginBottom: rem(96),
    }),
    media(HUGE_BREAKPOINT, {
      marginBottom: rem(112),
    }),
  ),
  footerLogo: {
    marginTop: rem(36),
    marginBottom: rem(-4),
  },
  brandInformations: {
    margin: 'auto',
    marginTop: rem(-24),
    marginBottom: rem(24),
    maxWidth: rem(620),
    textAlign: 'center',
  },
})

export default styles
