/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Swatch from './../Swatch'
import Wrapper from './../Wrapper'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Image from './../Image'
import styles from './styles'

type Props = {
  className?: string,
  title: string,
  content: string,
  renderCallToAction?: () => React$Node,
}

export default function Hero(props: Props) {
  const { className, title, content, renderCallToAction } = props
  return (
    <Swatch className={merge(css(styles.root), className)}>
      <Wrapper className={css(styles.container)}>
        <div className={css(styles.wrapper)}>
          <div className={css(styles.content)}>
            <Title className={css(styles.title)} level={1}>
              {title.split('\\n').map((child, index, arr) => {
                if (index === arr.length - 1) {
                  return <span key={index}>{child}</span>
                }
                return (
                  <span key={index}>
                    {child}
                    <br />
                  </span>
                )
              })}
            </Title>
            <Paragraph size="large" className={css(styles.description)}>
              {content.split('\\n').map((child, index, arr) => {
                if (index === arr.length - 1) {
                  return <span key={index}>{child}</span>
                }
                return (
                  <span key={index}>
                    {child}
                    <br />
                  </span>
                )
              })}
            </Paragraph>
            {typeof renderCallToAction === 'function' && renderCallToAction()}
          </div>
          <div className={css(styles.figure)}>
            <Image src="illu_home_general.png" lazy={false} />
          </div>
        </div>
      </Wrapper>
    </Swatch>
  )
}
