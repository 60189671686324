/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Title from './../Title'
import InboundForm from './../InboundForm'
import Image from './../Image'
import styles from './styles'

type Props = {
  className?: string,
  title: React$Node,
  inputPlaceholderText: string,
  buttonText: string,
  buttonLink: string,
}

export default function Inbound(props: Props) {
  const { className, title, inputPlaceholderText, buttonText, buttonLink } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <Image src="illu_home_inbound-left.png" className={css(styles.figure, styles.startFigure)} />
      <div className={css(styles.content)}>
        <Title level={2} className={css(styles.title)}>
          {title}
        </Title>
        <InboundForm
          className={css(styles.form)}
          align="center"
          inputAppearance="dark"
          inputPlaceholder={inputPlaceholderText}
          buttonText={buttonText}
          buttonLink={buttonLink}
        />
      </div>
      <Image src="illu_home_inbound-right.png" className={css(styles.figure, styles.endFigure)} />
    </div>
  )
}
