/* @flow */

import * as React from 'react'
import queryString from 'querystring'
import axios from 'axios'
import { css, merge } from 'glamor'
import { navigate } from 'gatsby'
import TextInput from './../TextInput'
import Button from './../Button'
import styles from './styles'
import constants from '../../constants'

import Cookies from 'js-cookie'
import { findUtmsInCookies } from '../../utils/utm'
import { validateEmail } from './utils'

const { CREATE_CONTACT_AFTER_WEBFORM_URI, TENANT_ID } = constants.plezi
const { FORM_ID, CONTENT_WEB_FORM_ID } = constants.plezi.forms.newsletter

type Props = {
  className?: string,
  align?: 'left' | 'center',
  inputPlaceholder: string,
  buttonAppearance?: 'light' | 'dark',
  buttonType?: 'primary' | 'secondary',
  buttonText: string,
  buttonLink: string,
}

type State = {|
  email: ?string,
|}

export default class NewsletterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const email = ''
    const error = validateEmail(email)
    const submitCount = 0
    this.state = { email, error, submitCount }
  }


  onChangeField = (name: string, value: ?string) => {
    this.setState({
      email: value,
      error: validateEmail(value),
    })
  }

  onSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault()
      const { email, error } = this.state

      if (error) {
        throw Error('Some errors')
      }

      const visit = Cookies.get('visit')
      const visitor = Cookies.get('visitor')
      const utms = findUtmsInCookies()

      await axios.get(
        `${CREATE_CONTACT_AFTER_WEBFORM_URI}?${queryString.stringify({
          form_id: FORM_ID,
          content_web_form_id: CONTENT_WEB_FORM_ID,
          tenant_id: TENANT_ID,
          email: email,
          visitor: (typeof visitor === 'string' && visitor.length > 0) ? visitor.split('-')[0] : undefined,
          visit: visit || undefined,
          ...utms,
        })}`,
        {
          headers: {
            'x-api-key': process.env.GATSBY_PLEZI_API_KEY,
            'X-Tenant-Company': 'Wino',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )

      navigate('/merci/')
    } catch (err) {
      const msg = `Context: /blog (à la validation du formulaire newsletter)\n${err}`
      if (err.message != 'fieldError') {
        throw new Error(msg)
      }
    } finally {
      this.setState(prevState => ({
        submitCount: prevState.submitCount + 1,
      }))
    }
  }

  render() {
    const {
      className,
      align,
      inputPlaceholder,
      buttonAppearance,
      buttonType,
      buttonText,
    } = this.props
    const { email, error, submitCount } = this.state
    return (
      <form id="newsletter-form" className={merge(css(styles.form, align === 'center' && styles.centeredForm), className)} onSubmit={this.onSubmit}>
        <TextInput
          className={css(styles.input)}
          size="large"
          placeholder={inputPlaceholder}
          name="newsletter-email"
          value={email}
          onChange={this.onChangeField}
        />
        {(error && Boolean(submitCount)) && <span className={css(styles.error)}>{error}</span>}
        <Button
          className={css(styles.button)}
          type={buttonType}
          appearance={buttonAppearance}
          state={this.state}
        >
          {buttonText}
        </Button>
      </form>
    )
  }
}
