/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'
import Image from './../Image'
import Strong from './../Strong'
import Span from './../Span'

type Props = {
  className?: string,
  media: string,
  title: string,
  description: string,
}

export default function Author(props: Props) {
  const { className, media, title, description } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <Image className={css(styles.media)} src={media} />
      <div className={merge(css(styles.content))}>
        <Strong>{title}</Strong>
        <Span>{description}</Span>
      </div>
    </div>
  )
}
