/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import { t } from './../../translations'
import Paragraph from './../Paragraph'
import Strong from './../Strong'
import Button from './../Button'
import styles from './styles'

type Props = {
  className?: string,
  content: string,
  title: string,
  taxonomy?: string,
  to: string,
}

export default function MinimalCalloutCard(props: Props) {
  const { className, content, title, taxonomy, to } = props
  return (
    <div
      className={merge(
        css(
          styles.container,
          taxonomy && styles.before,
          taxonomy && styles.after,
          taxonomy === 'fidelity' && styles.fidelity,
          taxonomy === 'pos' && styles.pos,
          taxonomy === 'business' && styles.business,
          taxonomy === 'dashboard' && styles.dashboard,
          taxonomy === 'multiboutique' && styles.multiboutique,
          taxonomy === 'potico' && styles.potico,
        ),
        className,
      )}
    >
      <div className={css(styles.wrapper)}>
        <Paragraph size="large" className={css(styles.content)}>
          {content}
        </Paragraph>
        <Strong size="large" className={css(styles.title)}>
          {title}
        </Strong>
        <Button
          type="secondary"
          appearance="dark"
          size="small"
          className={css(styles.button)}
          to={to}
        >
          {t('Learn more')}
        </Button>
      </div>
    </div>
  )
}
