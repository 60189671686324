/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors, fonts } from './../../theme'

const { ARCHIVO_SEMIBOLD } = fonts
const { NEUTRAL_COLOR_25, BRAND_COLOR_50 } = colors

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  contentColumn: {
    maxWidth: rem(550),
    borderRadius: rem(4),
    border: rem(1) + ' solid ' + NEUTRAL_COLOR_25,
    padding: rem(55),
    paddingRight: rem(180),
  },
  title: {
    color: BRAND_COLOR_50,
    margin: 0,
    ...ARCHIVO_SEMIBOLD,
    fontSize: rem(28),
    lineHeight: rem(36),
  },
  button: {
    marginTop: rem(10),
  },
  productColumn: {
    position: 'absolute',
    right: rem(-50),
    top: rem(-70),
    width: rem(382),
    height: rem(382),
    borderRadius: rem(4),
  },
  productImg: {
    width: '100%',
    height: '100%',
    borderRadius: rem(4),
  },
  mediaWrapper: {
    position: 'relative',
  },
  media: {
    position: 'absolute',
    top: rem(10),
    width: rem(290),
    right: rem(0),
  },
})

export default styles
