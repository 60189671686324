/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, after, before } from 'glamor'
import { rem } from 'polished'
import { colors, grid, breakpoints, utils } from '../../theme'
import { LARGE_BREAKPOINT } from '../../theme/breakpoints'

const { NEUTRAL_COLOR_20 } = colors
const { DEFAULT_GUTTER } = grid
const { SMALL_BREAKPOINT } = breakpoints
const { generateImagePath } = utils

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      paddingTop: rem(24),
      paddingBottom: rem(24),
      paddingRight: DEFAULT_GUTTER,
      paddingLeft: DEFAULT_GUTTER,
      marginTop: rem(30),
      marginBottom: rem(72),
      border: rem(1) + ' solid ' + NEUTRAL_COLOR_20,
      borderRadius: rem(5),
      textAlign: 'center',
    },
    media(SMALL_BREAKPOINT, {
      paddingRight: DEFAULT_GUTTER * 2,
      paddingLeft: DEFAULT_GUTTER * 2,
      paddingTop: rem(40),
      paddingBottom: rem(40),
      textAlign: 'left',
    }),
    before(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: rem(32),
        width: rem(147),
        height: rem(132),
        zIndex: 1,
        backgroundImage: `url(${generateImagePath('illu_cashregister_desktop.png')})`,
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }),
    ),
    after(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        right: rem(-55),
        bottom: rem(50),
        width: rem(161),
        height: rem(110),
        zIndex: 1,
        backgroundImage: `url(${generateImagePath('illu_speechbubble_desktop.png')})`,
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }),
    ),
  ),
  title: merge(
    {
      margin: 0,
      fontSize: rem(24),
      textAlign: 'center',
    },
    media(SMALL_BREAKPOINT, {
      width: '80%',
    })
  ),
  span: merge(
    {
      display: 'block',
      marginTop: rem(10),
      marginBottom: 0,
      lineHeight: rem(22),
    },
  ),
  button: {
    marginTop: rem(30),
  },
})

export default styles
