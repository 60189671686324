/* @flow */

import * as React from 'react'
import Image from './../Image'

type Props = {
  name: string,
}

export default function DropdownIcon(props: Props) {
  switch (props.name) {
    case 'point-of-sale':
      return <Image lazy={false} src="icon_nav_pointofsales.png" />
    case 'business':
      return <Image lazy={false} src="icon_nav_business.png" />
    case 'fidelity':
      return <Image lazy={false} src="icon_nav_fidelity.png" />
    case 'dashboard':
      return <Image lazy={false} src="icon_nav_dashboard.png" />
    case 'click-and-collect':
      return <Image lazy={false} src="icon_nav_clickandcollect.png" />
    case 'multiboutique':
      return <Image lazy={false} src="icon_nav_multishop.png" />
    case 'ecommerce':
      return <Image lazy={false} src="icon_nav_ecommerce.png" />
    case 'cheeseshop':
      return <Image lazy={false} src="icon_nav_cheeseshop.png" />
    case 'wineshop':
      return <Image lazy={false} src="icon_nav_wineshop.png" />
    case 'groceryshop':
      return <Image lazy={false} src="icon_nav_groceryshop.png" />
    default:
      return null
  }
}
