/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints, grid } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { DEFAULT_GUTTER } = grid

const styles = StyleSheet.create({
  main: merge(
    {
      marginLeft: rem(DEFAULT_GUTTER),
      marginRight: rem(DEFAULT_GUTTER),
      maxWidth: rem(960),
    },
    media(LARGE_BREAKPOINT, {
      margin: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
    }),
  ),
  large: merge(
    {
      paddingTop: rem(12),
      paddingBottom: rem(12),
    },
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(52),
      paddingBottom: rem(64),
    }),
  ),
})

export default styles
