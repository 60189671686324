/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, after } from 'glamor'
import { rem } from 'polished'
import { fonts, colors, breakpoints, grid, utils, shadows } from './../../theme'

const { row, column } = grid
const { LIBRE_FRANKLIN_REGULAR } = fonts
const {
  NEUTRAL_COLOR_00,
  NEUTRAL_COLOR_10,
  NEUTRAL_COLOR_20,
  NEUTRAL_COLOR_40,
  NEUTRAL_COLOR_50,
} = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints
const { reverseToMaxBreakpoint } = utils
const { BLACK_SHADOW_75 } = shadows

const styles = StyleSheet.create({
  container: merge(
    {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: rem(24),
      marginBottom: rem(32),
    },
    media(MEDIUM_BREAKPOINT, {
      ...row(),
      marginBottom: rem(24),
    }),
  ),
  fantasyContainer: merge(
    after(
      media(LARGE_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        left: rem(-58),
        right: rem(54),
        top: rem(64),
        bottom: rem(-32),
        backgroundColor: NEUTRAL_COLOR_10,
        borderRadius: rem(4),
      }),
    ),
  ),
  largeContainer: merge(
    {
      marginTop: rem(32),
      marginBottom: rem(32),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(68),
      marginBottom: rem(68),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(0),
      marginBottom: rem(0),
    }),
  ),
  containerWithHugeMedia: merge(
    media(LARGE_BREAKPOINT, {
      marginTop: rem(0),
      marginBottom: rem(0),
    }),
  ),
  containerLtr: media(MEDIUM_BREAKPOINT, {
    flexDirection: 'row',
  }),
  containerRtl: media(MEDIUM_BREAKPOINT, {
    flexDirection: 'row-reverse',
  }),
  columnContent: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(6 / 12),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(5 / 12),
    }),
  ),
  label: merge(
    {
      ...LIBRE_FRANKLIN_REGULAR,
      fontSize: rem(13),
      textTransform: 'uppercase',
      letterSpacing: rem(0.5),
      color: NEUTRAL_COLOR_40,
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(14),
    }),
  ),
  title: merge(
    media(reverseToMaxBreakpoint(MEDIUM_BREAKPOINT), {
      maxWidth: rem(400),
    }),
  ),
  darkTitle: {
    color: NEUTRAL_COLOR_00,
  },
  lightParagraph: {
    color: NEUTRAL_COLOR_50,
  },
  darkParagraph: {
    color: NEUTRAL_COLOR_20,
  },
  content: {
    marginBottom: rem(25),
  },
  fantasyContent: merge(
    media(LARGE_BREAKPOINT, {
      marginTop: rem(112),
    }),
  ),
  largeParagraph: {
    marginBottom: rem(32),
  },
  columnMedia: merge(
    {
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'stretch',
    },
    media(MEDIUM_BREAKPOINT, {
      alignSelf: 'center',
      ...column(6 / 12),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(7 / 12),
    }),
  ),
  columnMediaRtl: merge(
    media(MEDIUM_BREAKPOINT, {
      justifyContent: 'flex-start',
    }),
  ),
  columnMediaLtr: merge(
    media(MEDIUM_BREAKPOINT, {
      justifyContent: 'flex-end',
    }),
  ),
  media: merge(
    {
      position: 'relative',
      maxHeight: rem(375),
      maxWidth: rem(400),
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: 0,
      maxWidth: rem(450),
      maxHeight: rem(745),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: 0,
      maxWidth: rem(550),
      maxHeight: rem(845),
    }),
  ),
  mediaElevation: merge({
    ...BLACK_SHADOW_75,
  }),
  mediaElevationLtr: merge(
    media(LARGE_BREAKPOINT, {
      left: rem(72),
    }),
  ),
  mediaElevationRtl: merge(
    media(LARGE_BREAKPOINT, {
      left: rem(-72),
    }),
  ),
  mediaWithChildrenOnIt: {
    position: 'relative',
    marginTop: rem(50),
  },
  largeMedia: merge(
    {
      maxWidth: '100%',
      maxHeight: rem(275),
      objectFit: 'cover',
    },
    media(MEDIUM_BREAKPOINT, {
      height: '100%',
      maxHeight: '100%',
    }),
  ),
  hugeMedia: media(HUGE_BREAKPOINT, {
    marginTop: rem(0),
    marginBottom: rem(0),
    top: rem(-25),
    transform: 'scale(1.25)',
  }),
  hugeMediaRtl: media(HUGE_BREAKPOINT, {
    left: rem(-82),
  }),
  hugeMediaLtr: media(HUGE_BREAKPOINT, {
    right: rem(-82),
  }),
  button: {
    display: 'inline-block',
    marginTop: rem(-2),
    marginBottom: rem(12),
  },
})

export default styles
