/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Image from './../Image'
import Badge from './../Badge'
import Title from './../Title'
import Paragraph from './../Paragraph'
import styles from './styles'

type Props = {
  className: string,
  badge: string,
  media?: string,
  title: string,
  content: string,
}

export default function Highlight(props: Props) {
  const { className, media, badge, title, content } = props
  return (
    <div className={merge(css(styles.container), className)}>
      {badge && <Badge>{badge}</Badge>}
      <Title className={css(styles.title)} level={2}>
        {title}
      </Title>
      <Paragraph className={css(styles.content)}>{content}</Paragraph>
      {media && <Image className={css(styles.media)} src={media} />}
    </div>
  )
}
