/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, hover } from 'glamor'
import { rem } from 'polished'
import { breakpoints, colors, grid } from './../../theme'

const { NEUTRAL_COLOR_10, NEUTRAL_COLOR_25, NEUTRAL_COLOR_40, BRAND_COLOR_50 } = colors
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { row, column } = grid

const styles = StyleSheet.create({
  container: merge(
    {
      position: 'relative',
      backgroundColor: NEUTRAL_COLOR_10,
      margin: 'auto',
      maxWidth: rem(960),
    },
    media(LARGE_BREAKPOINT, {
      borderRadius: 6,
      height: rem(345),
      overflow: 'hidden',
    }),
  ),
  wrapper: merge(
    media(MEDIUM_BREAKPOINT, {
      ...row(),
      alignItems: 'center',
    }),
  ),
  images: merge(
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 2),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(5 / 12),
    }),
  ),
  image: merge(
    {
      width: '100%',
      height: rem(300),
      objectFit: 'cover',
      objectPosition: '50% 50%',
      backgroundColor: NEUTRAL_COLOR_25,
    },
    media(SMALL_BREAKPOINT, {
      height: rem(325),
    }),
    media(MEDIUM_BREAKPOINT, {
      height: rem(350),
    }),
    media(LARGE_BREAKPOINT, {
      height: rem(350),
    }),
  ),
  content: merge(
    {
      paddingTop: rem(24),
      paddingBottom: rem(32),
      borderRadius: rem(4),
    },
    media(MEDIUM_BREAKPOINT, {
      ...column(1 / 2),
    }),
    media(LARGE_BREAKPOINT, {
      ...column(7 / 12),
    }),
  ),
  dots: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: rem(28),
  },
  dot: merge(
    {
      marginRight: rem(7),
      height: rem(7),
      width: rem(7),
      borderRadius: rem(50),
      backgroundColor: NEUTRAL_COLOR_25,
      cursor: 'pointer',
    },
    hover({
      backgroundColor: NEUTRAL_COLOR_40,
    }),
  ),
  active: {
    backgroundColor: BRAND_COLOR_50,
  },
  arrow: merge(
    {
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
      marginTop: rem(2),
      height: rem(25),
      zIndex: 10,
      opacity: 0.5,
      cursor: 'pointer',
      outline: 'none',
    },
    hover({
      opacity: 1,
    }),
  ),
  leftArrow: {
    left: rem(0),
  },
  rightArrow: {
    right: rem(20),
  },
})

export default styles
