/* @flow */

import { StyleSheet } from 'glamor/aphrodite'

const styles = StyleSheet.create({
  media: {
    maxWidth: '100%',
  },
})

export default styles
