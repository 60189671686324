/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import { generateSrcset, generateSrc } from './utils'
import styles from './styles'
import 'lazysizes'

type Props = {
  lazy?: boolean,
  className?: string,
  alt?: string,
  src: string,
  srcset?: string,
}

function Image(props: Props) {
  const { src, alt, lazy, className, srcset } = props
  const imageAttributes = lazy
    ? { 'data-src': generateSrc(src), 'data-srcset': srcset ? srcset : generateSrcset(src) }
    : { src: generateSrc(src), srcSet: srcset ? srcset : generateSrcset(src) }

  return (
    <img
      alt={alt}
      className={[merge(css(styles.main), className), lazy && 'lazyload'].filter(Boolean).join(' ')}
      draggable={false}
      {...imageAttributes}
    />
  )
}

Image.defaultProps = {
  lazy: true,
}

export default Image
