import { StyleSheet } from 'glamor/aphrodite'
import { media, merge, hover } from 'glamor'
import { colors, breakpoints, shadows, utils } from './../../theme'
import { rem, rgba } from 'polished'

const { NEUTRAL_COLOR_90 } = colors
const { BLACK_SHADOW_85 } = shadows
const { flexCenter } = utils

const { MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  global: merge(
    {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      width: '100%',
      height: rem(350),
      overflow: 'hidden',
      ...BLACK_SHADOW_85,
      borderRadius: rem(5),
      boxSizing: 'border-box',
      padding: `${rem(30)} ${rem(40)}`,
      margin: `${rem(20)} 0`,
      transition: 'box-shadow ease .2s, transform ease .2s',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
      height: rem(250),
    }),
    hover({
      boxShadow: `0 2px 30px ${rgba(NEUTRAL_COLOR_90, 0.175)}`,
      transform: 'translateY(-3px)',
    }),
  ),
  bannerTextContainer: merge(
    {
      width: '100%',
      height: '80%',
      ...flexCenter,
      flexDirection: 'column',
      padding: rem(1),
    },
    media(MEDIUM_BREAKPOINT, {
      width: '60%',
      height: '100%',
    }),
  ),
  bannerTexts: {
    width: '100%',
    margin: `${rem(5)} 0`,
  },
  bannerButtonContainer: merge(
    {
      width: '100%',
      height: '20%',
      ...flexCenter,
    },
    media(MEDIUM_BREAKPOINT, {
      height: '100%',
      width: '40%',
    }),
  ),
  bannerImg: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
  },
  light: {
    color: NEUTRAL_COLOR_90,
  },
  dark: {
    color: 'white',
  },
  link: {
    textDecorationLine: 'none',
  },
})

export default styles
