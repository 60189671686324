/* @flow */

import { css } from 'glamor'
import { rem, em } from 'polished'
import {
  LIBRE_FRANKLIN_REGULAR,
  LIBRE_FRANKLIN_SEMIBOLD,
  LIBRE_FRANKLIN_BOLD,
  ARCHIVO_SEMIBOLD,
  ARCHIVO_BOLD,
} from './fonts'
import {
  BRAND_COLOR_50,
  BRAND_COLOR_60,
  NEUTRAL_COLOR_50,
  NEUTRAL_COLOR_70,
  NEUTRAL_COLOR_90,
  BRAND_COLOR_10,
} from './colors'

css.global('html', {
  fontSize: '100%',
})

css.global('body', {
  maxWidth: '100vw',
  overflowX: 'hidden',
})

css.insert(
  '.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{width:100%;box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:""}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}',
)

css.global('::selection', {
  background: BRAND_COLOR_10,
})

css.global('p, li, strong, th, td', {
  ...LIBRE_FRANKLIN_REGULAR,
  color: NEUTRAL_COLOR_70,
  fontSize: em(16),
  lineHeight: em(27),
})

css.global('a', {
  cursor: 'pointer',
  ...LIBRE_FRANKLIN_SEMIBOLD,
  textDecoration: 'underline',
  color: BRAND_COLOR_50,
  fontSize: em(16),
})

css.global('a:hover', {
  color: BRAND_COLOR_60,
})

css.global('th', {
  textAlign: 'left',
  verticalAlign: 'top',
})

css.global('li', {
  marginBottom: rem(6),
})

css.global('strong', {
  ...LIBRE_FRANKLIN_BOLD,
  color: NEUTRAL_COLOR_90,
})

css.global('h2', {
  color: NEUTRAL_COLOR_90,
  ...ARCHIVO_SEMIBOLD,
  fontSize: rem(24),
  lineHeight: rem(32),
  marginBlockEnd: '0.25em',
})

css.global('h3', {
  color: NEUTRAL_COLOR_90,
  ...ARCHIVO_SEMIBOLD,
  fontSize: rem(20),
  lineHeight: rem(28),
})

css.global('h4', {
  color: NEUTRAL_COLOR_90,
  ...ARCHIVO_BOLD,
  fontSize: rem(20),
  lineHeight: rem(28),
})

css.global('h5', {
  color: NEUTRAL_COLOR_50,
  ...ARCHIVO_SEMIBOLD,
  fontSize: rem(18),
  lineHeight: em(24),
})

css.global('table', {
  marginTop: rem(8),
  marginBottom: rem(8),
})
