/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import Paragraph from './../Paragraph'
import Inline from './../Inline'
import Span from './../Span'
import Strong from './../Strong'
import styles from './styles'

type Props = {
  content: string,
  name: string,
  companyName: string,
}

export default function Testimonial(props: Props) {
  const { content, name, companyName } = props
  return (
    <div className={css(styles.container)}>
      <Paragraph className={css(styles.content)} size="huge">
        « {content} »
      </Paragraph>
      <Inline className={css(styles.inline)}>
        <Span size="small" className={css(styles.name)}>
          {name}
        </Span>
        <Strong size="small" className={css(styles.companyName)}>
          {companyName}
        </Strong>
      </Inline>
    </div>
  )
}
