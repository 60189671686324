/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  className?: string,
}

export default function DropdownContent(props: Props) {
  const { children, className } = props
  return <span className={merge(css(styles.main), className)}>{children}</span>
}
