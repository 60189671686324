/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, after } from 'glamor'
import { rem } from 'polished'
import { colors, grid, breakpoints, utils } from './../../theme'
const { flexCenter } = utils

const { NEUTRAL_COLOR_20 } = colors
const { DEFAULT_GUTTER } = grid
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT } = breakpoints
const { generateImagePath } = utils

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      paddingTop: rem(24),
      paddingBottom: rem(24),
      paddingRight: DEFAULT_GUTTER,
      paddingLeft: DEFAULT_GUTTER,
      marginBottom: rem(72),
      border: rem(1) + ' solid ' + NEUTRAL_COLOR_20,
      gap: DEFAULT_GUTTER,
      borderRadius: rem(5),
      textAlign: 'center',
    },
    media(SMALL_BREAKPOINT, {
      paddingRight: DEFAULT_GUTTER * 2,
      paddingLeft: DEFAULT_GUTTER * 2,
      paddingTop: rem(40),
      paddingBottom: rem(40),
      flexDirection: 'row',
      textAlign: 'left',
    }),
    after(
      media(MEDIUM_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        right: rem(-55),
        bottom: rem(50),
        width: rem(79),
        height: rem(88),
        zIndex: 1,
        backgroundImage: `url(${generateImagePath('illu_newsletter_desktop.png')})`,
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }),
    ),
  ),
  texts: merge(
    {
      ...flexCenter,
      flexDirection: 'column',
    },
    media(SMALL_BREAKPOINT, {
      alignItems: 'start',
    })
  ),
  title: {
    margin: 0,
  },
  span: merge(
    {
      display: 'block',
      width: '100%',
      marginTop: rem(10),
      marginBottom: 0,
      lineHeight: rem(22),
    },
    media(SMALL_BREAKPOINT, {
      width: '80%',
    })
  ),
})

export default styles
