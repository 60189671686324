/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { merge, media } from 'glamor'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_20, NEUTRAL_COLOR_90 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT, HUGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      marginTop: rem(12),
      marginBottom: rem(12),
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(14),
      marginBottom: rem(18),
    }),
    media(HUGE_BREAKPOINT, {
      marginTop: rem(20),
      marginBottom: rem(24),
    }),
  ),
  wrapper: {
    marginTop: rem(12),
  },
  media: {
    width: '100%',
    border: rem(1) + ' solid ' + NEUTRAL_COLOR_20,
    borderRadius: rem(4),
  },
  title: {
    margin: 0,
    color: NEUTRAL_COLOR_90,
  },
  content: merge(
    {
      marginTop: rem(12),
      marginBottom: rem(24),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(8),
      marginBottom: rem(20),
    }),
  ),
})

export default styles
