/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 8,
      textDecoration: 'none',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      marginBottom: 16,
    }),
  ),
  icon: merge(
    {
      display: 'none',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'block',
      marginTop: rem(-2),
      width: rem(40),
      height: rem(45),
    }),
  ),
  text: {
    flex: 1,
    paddingLeft: rem(18),
  },
  title: {
    marginBottom: rem(4),
  },
  content: merge(
    {
      display: 'none',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'block',
    }),
  ),
})

export default styles
