/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
  level?: 1 | 2 | 3 | 4 | 5,
  className?: string,
}

export default class Title extends React.Component<Props> {
  static defaultProps = {
    level: 1,
  }

  render() {
    const { className, level, children } = this.props
    const tag = `h${+level}`
    return React.createElement(tag, { className: merge(css(styles[tag]), className) }, children)
  }
}
