/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_90, BRAND_COLOR_50, SECONDARY_COLOR_50 } = colors
const { LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  title: merge(
    {
      color: NEUTRAL_COLOR_90,
      marginTop: rem(2),
      marginBottom: rem(5),
    },
    media(LARGE_BREAKPOINT, {
      marginTop: rem(15),
      marginBottom: rem(7),
    }),
  ),
  largeTitle: {
    fontSize: rem(24),
  },
  content: merge(
    {
      margin: `${rem(0)} auto`,
      maxWidth: rem(300),
    },
    media(LARGE_BREAKPOINT, {
      maxWidth: '100%',
    }),
  ),
  centeredContainer: {
    textAlign: 'center',
  },
  primaryTitle: {
    color: BRAND_COLOR_50,
  },
  secondaryTitle: {
    color: SECONDARY_COLOR_50,
  },
  media: {
    width: rem(72),
  },
  link: {
    marginTop: rem(30),
  },
})

export default styles
