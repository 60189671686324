/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'
import Wrapper from './../Wrapper'

type Props = {
  className?: string,
  children: React$Node,
}

export default function Showcase(props: Props) {
  const { className, children } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <Wrapper className={css(styles.wrapper)}>
        {React.Children.map(children, child =>
          React.cloneElement(child, { className: css(styles.img) }),
        )}
      </Wrapper>
    </div>
  )
}
