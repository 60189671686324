/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, hover } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: {},
  wrapper: merge(
    {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflowX: 'scroll',
      overflowY: 'hidden',
      height: rem(52),
      paddingTop: rem(18),
      paddingBottom: rem(18),
    },
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(24),
      paddingBottom: rem(24),
      height: rem(58),
    }),
    media(LARGE_BREAKPOINT, {
      overflowX: 'hidden',
    }),
  ),
  img: merge(
    {
      marginRight: rem(36),
      opacity: 0.625,
      maxHeight: '100%',
      transition: 'opacity 200ms ease',
    },
    hover({
      opacity: 1,
    }),
  ),
})

export default styles
