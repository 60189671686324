/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import styles from './styles'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Button from './../Button'
import Image from './../Image'

type Props = {
  title: string,
  content: string,
  buttonAppearance: 'light' | 'dark',
  buttonType: 'primary' | 'secondary',
  buttonText: string,
  productImg: string,
  media: string,
}

export default function Product(props: Props) {
  const { title, content, buttonAppearance, buttonType, buttonText, productImg, media } = props
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.contentColumn)}>
        <Title level={2} className={css(styles.title)}>
          {title}
        </Title>
        <Paragraph>{content}</Paragraph>
        <Button className={css(styles.button)} appearance={buttonAppearance} type={buttonType}>
          {buttonText}
        </Button>
        <div className={css(styles.mediaWrapper)}>
          <Image className={css(styles.media)} src={media} />
        </div>
      </div>
      <div className={css(styles.productColumn)}>
        <Image className={css(styles.productImg)} src={'product/' + productImg} />
      </div>
    </div>
  )
}
