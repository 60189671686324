/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge } from 'glamor'
import { rem, padding } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  input: merge(
    {
      ...padding(rem(14), rem(16), rem(13)),
      marginBottom: 6,
      width: '100%',
      boxSizing: 'border-box',
      borderWidthBottom: 0,
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginBottom: 0,
      borderWidthBottom: 1,
      borderWidthRight: 0,
      borderTopRightRadius: 0,
      textAlign: 'left',
    }),
  ),
  button: merge(
    {
      width: '100%',
      flex: 1,
      whiteSpace: 'nowrap',
    },
    media(MEDIUM_BREAKPOINT, {
      borderTopRightRadius: rem(4),
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      verticalAlign: 'middle',
    }),
  ),
})

export default styles
