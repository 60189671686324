/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, hover } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const {
  NEUTRAL_COLOR_00,
  NEUTRAL_COLOR_10,
  NEUTRAL_COLOR_30,
  NEUTRAL_COLOR_50,
  NEUTRAL_COLOR_80,
} = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  container: merge(
    {
      paddingTop: rem(20),
      paddingBottom: rem(20),
      borderBottom: `1px solid ${NEUTRAL_COLOR_10}`,
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      width: '100%',
      zIndex: 10,
    },
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(25),
      paddingBottom: rem(25),
    }),
  ),
  wrapper: merge(
    media(
      NAV_BAR_MAJOR_BREAKPOINT,
      merge({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }),
    ),
  ),
  heading: merge({
    display: 'flex',
    alignItems: 'center',
  }),
  logo: merge(
    hover({
      opacity: 0.5,
    }),
  ),
  contact: merge(
    {
      display: 'flex',
      marginLeft: rem(16),
      paddingLeft: rem(16),
      marginTop: rem(4),
      borderLeft: `1px solid ${NEUTRAL_COLOR_30}`,
      color: NEUTRAL_COLOR_80,
      fontSize: rem(15),
      textDecoration: 'none',
    },
    hover({
      color: NEUTRAL_COLOR_80,
      textDecoration: 'underline',
    }),
  ),
  minimalistText: {
    display: 'flex',
    marginLeft: rem(16),
    paddingLeft: rem(16),
    marginTop: rem(4),
    borderLeft: `1px solid ${NEUTRAL_COLOR_30}`,
    color: NEUTRAL_COLOR_50,
    fontSize: rem(15),
    textDecoration: 'none',
  },
  icon: {
    position: 'relative',
    top: rem(-1),
    marginRight: rem(5),
  },
  burger: merge(
    {
      position: 'absolute',
      marginTop: 1,
      right: rem(20),
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'none',
    }),
  ),
  menu: merge(
    {
      display: 'none',
      width: '100%',
      marginTop: rem(30),
      paddingBottom: rem(0),
      height: 0,
      backgroundColor: NEUTRAL_COLOR_00,
      transition: 'height 1s ease',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      flex: 1,
      margin: 0,
      padding: 0,
    }),
  ),
  openMenu: {
    display: 'block',
    height: 'auto',
  },
  navBar: merge(
    media(MEDIUM_BREAKPOINT, {
      flex: 1,
      position: 'relative',
      marginRight: rem(24),
    }),
    media(LARGE_BREAKPOINT, {
      marginRight: rem(24),
    }),
  ),
  actions: merge(
    {
      marginTop: rem(22),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(1),
    }),
  ),
  flex: {
    display: 'flex',
  },
})

export default styles
