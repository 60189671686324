/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover } from 'glamor'
import { colors, fonts } from './../../theme'
import { rem } from 'polished'

const {
  BRAND_COLOR_50,
  BRAND_COLOR_60,
  NEUTRAL_COLOR_00,
  SECONDARY_COLOR_50,
  SECONDARY_COLOR_60,
} = colors

const { LIBRE_FRANKLIN_SEMIBOLD } = fonts

const styles = StyleSheet.create({
  main: {
    cursor: 'pointer',
    ...LIBRE_FRANKLIN_SEMIBOLD,
    textDecoration: 'underline',
    color: NEUTRAL_COLOR_00,
    fontSize: rem(16),
  },
  tiny: {
    fontSize: rem(13.5),
  },
  small: {
    fontSize: rem(14),
  },
  normal: {
    fontSize: rem(16),
  },
  primary: merge(
    {
      color: BRAND_COLOR_50,
    },
    hover({
      color: BRAND_COLOR_60,
    }),
  ),
  secondary: merge(
    {
      color: SECONDARY_COLOR_50,
    },
    hover({
      color: SECONDARY_COLOR_60,
    }),
  ),
})

export default styles
