/* @flow */

import * as React from 'react'
import { css } from 'glamor/aphrodite'
import Image from './../Image'
import styles from './styles'

type Props = {
  authorImage: string,
  authorName: string,
  authorCompany: string,
  content: string,
}

export default function Feedback(props: Props) {
  const { authorImage, authorName, authorCompany, content } = props
  return (
    <div className={css(styles.container)}>
      <Image className={css(styles.profilePic)} src={authorImage} />
      <div className={css(styles.feedback)}>
        <span className={css(styles.content)}>{content}</span>
        <div className={css(styles.infos)}>
          <span className={css(styles.authorName)}>{authorName}</span>
          <span className={css(styles.authorCompany)}>{authorCompany}</span>
        </div>
      </div>
    </div>
  )
}
