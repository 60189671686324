/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  size?: 'tiny' | 'small' | 'normal' | 'medium' | 'large' | 'huge',
  children: React$Node,
}

export default function Paragraph(props: Props) {
  const { children, className, size } = props
  return (
    <p
      className={merge(
        css(
          styles.main,
          size === 'tiny' && styles.tiny,
          size === 'small' && styles.small,
          size === 'medium' && styles.medium,
          size === 'large' && styles.large,
          size === 'huge' && styles.huge,
        ),
        className,
      )}
    >
      {children}
    </p>
  )
}
