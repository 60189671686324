/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints, fonts, colors } from './../../theme'

const { MEDIUM_BREAKPOINT } = breakpoints
const { LIBRE_FRANKLIN_REGULAR } = fonts
const { NEUTRAL_COLOR_40 } = colors

const styles = StyleSheet.create({
  label: merge(
    {
      ...LIBRE_FRANKLIN_REGULAR,
      fontSize: rem(16),
      letterSpacing: rem(0.5),
      color: NEUTRAL_COLOR_40,
    },
    media(MEDIUM_BREAKPOINT, {
      fontSize: rem(16),
    }),
  ),
  link: {
    marginLeft: rem(6),
  },
})

export default styles
