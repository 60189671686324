/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import TextInput from './../TextInput'
import Button from './../Button'
import Span from './../Span'
import Inline from './../Inline'
import Link from './../Link'
import styles from './styles'

type Props = {
  className?: string,
  align?: 'left' | 'center',
  lastNameInputPlaceholder: string,
  firstNameInputPlaceholder: string,
  emailInputPlaceholder: string,
  signUpButtonText: string,
  signUpButtonLink: string,
  signInSpanText: string,
  signInLinkText: string,
  signInLinkTo: string,
}

type State = {|
  firstName: ?string,
  lastName: ?string,
  email: ?string,
|}

export default class DiscoverForm extends React.Component<Props, State> {
  state = {
    firstName: null,
    lastName: null,
    email: null,
  }

  onChangeField = (name: string, value: ?string) => {
    this.setState({
      [name]: value,
    })
  }

  render() {
    const {
      className,
      align,
      lastNameInputPlaceholder,
      firstNameInputPlaceholder,
      emailInputPlaceholder,
      signUpButtonText,
      signUpButtonLink,
      signInSpanText,
      signInLinkText,
      signInLinkTo,
    } = this.props
    return (
      <div
        className={merge(
          css(styles.container, align === 'center' && styles.centeredContainer),
          className,
        )}
      >
        <div className={css(styles.group)}>
          <TextInput
            name="lastName"
            className={css(styles.input)}
            placeholder={lastNameInputPlaceholder}
            type="text"
            onChange={this.onChangeField}
          />
          <TextInput
            name="firstName"
            className={css(styles.input)}
            placeholder={firstNameInputPlaceholder}
            type="text"
            onChange={this.onChangeField}
          />
        </div>
        <TextInput
          name="email"
          className={css(styles.input)}
          placeholder={emailInputPlaceholder}
          type="email"
          onChange={this.onChangeField}
        />
        <div className={css(styles.actions)}>
          <Button
            size="large"
            to={signUpButtonLink}
            state={this.state}
            className={css(styles.button)}
          >
            {signUpButtonText}
          </Button>
          <Inline className={css(styles.link)}>
            <Span className={css(styles.span)} size="small">
              {signInSpanText}
            </Span>
            <Link size="small" href={signInLinkTo}>
              {signInLinkText}
            </Link>
          </Inline>
        </div>
      </div>
    )
  }
}
