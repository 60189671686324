/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

import Title from '../Title'
import Button from '../Button'
import Span from '../Span'

type Props = {
  className?: string,
  title: string,
  subtitle: string,
  buttonAppearance?: 'light' | 'dark',
  buttonType?: 'primary' | 'secondary',
  buttonText: string,
  buttonLink: string,
}

export default class DemoCard extends React.Component<Props> {
  render() {
    const { title, subtitle, buttonAppearance, buttonType, buttonText, buttonLink } = this.props
    return (
      <div className={merge(css(styles.container))}>
        <Title level={2} className={css(styles.title)}>{title}</Title>
        <Span className={css(styles.span)}>{subtitle}</Span>
        <Button
          className={css(styles.button)}
          type={buttonType}
          appearance={buttonAppearance}
          to={buttonLink}
        >
          {buttonText}
        </Button>
      </div>
    )
  }
}