/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import Span from './../Span'
import styles from './styles'

export default function FooterCopyright() {
  return (
    <Span size="small" className={css(styles.main)}>
      © Copyright {new Date().getFullYear()} - Wino Technologies SAS
    </Span>
  )
}
