/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import { t } from './../../translations'
import Image from './../Image'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Link from './../Link'
import styles from './styles'

type Props = {
  className?: string,
  to?: string,
  media: string,
  title: string,
  content: string,
}

export default function Card(props: Props) {
  const { className, media, title, content, to } = props
  return (
    <div className={merge(css(styles.container), className)}>
      <div>
        <Image className={css(styles.media)} src={media} />
      </div>
      <div className={css(styles.wrapper)}>
        <Title className={css(styles.title)} level={3}>
          {title}
        </Title>
        <Paragraph className={css(styles.content)}>{content}</Paragraph>
        {to && <Link to={to}>{t('Learn more')}</Link>}
      </div>
    </div>
  )
}
