/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  size?: 'small' | 'normal' | 'medium' | 'large',
  children: React$Node,
}

export default class Span extends React.Component<Props> {
  static defaultProps = {
    size: 'normal',
  }

  render() {
    const { children, className, size } = this.props
    return (
      <span
        className={merge(
          css(
            styles.main,
            size === 'small' && styles.small,
            size === 'medium' && styles.medium,
            size === 'large' && styles.large,
          ),
          className,
        )}
      >
        {children}
      </span>
    )
  }
}
