/* @flow */

// Primary tones - Disco
export const BRAND_COLOR_10 = '#dcadcb'
export const BRAND_COLOR_50 = '#a01e72'
export const BRAND_COLOR_60 = '#8a0262'

// Red tones - Amaranthapprox
export const DANGER_COLOR_50 = '#e61e5a'
export const DANGER_COLOR_55 = '#c81c51'
export const DANGER_COLOR_60 = '#ce0340'

// Secondary tones - Havelock
export const SECONDARY_COLOR_10 = '#e0e5f5'
export const SECONDARY_COLOR_50 = '#647ccc'
export const SECONDARY_COLOR_60 = '#4962b6'

// Neutral tones - Cadet
export const NEUTRAL_COLOR_00 = '#fefefe'
export const NEUTRAL_COLOR_05 = '#f8f8fb'
export const NEUTRAL_COLOR_10 = '#f3f3f7'
export const NEUTRAL_COLOR_15 = '#e7e7ee'
export const NEUTRAL_COLOR_20 = '#e5e5eb'
export const NEUTRAL_COLOR_25 = '#d7d7e0'
export const NEUTRAL_COLOR_30 = '#c2c2c9'
export const NEUTRAL_COLOR_40 = '#a2a1b0'
export const NEUTRAL_COLOR_50 = '#767589'
export const NEUTRAL_COLOR_55 = '#797885'
export const NEUTRAL_COLOR_65 = '#535165'
export const NEUTRAL_COLOR_70 = '#494859'
export const NEUTRAL_COLOR_80 = '#35334b'
export const NEUTRAL_COLOR_90 = '#25243a'

// Black tones
export const BLACK_COLOR__40 = '#bdbdca'

// Success tones
export const SUCCESS_COLOR_50 = '#1dbb7a'
