/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media, before, after } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints, grid, utils } from './../../theme'

const { NEUTRAL_COLOR_20, NEUTRAL_COLOR_90 } = colors
const { SMALL_BREAKPOINT, MEDIUM_BREAKPOINT } = breakpoints
const { row, column, offset, DEFAULT_GUTTER } = grid
const { generateImagePath } = utils

const styles = StyleSheet.create({
  container: merge(
    {
      ...row(),
      marginTop: rem(50),
      marginBottom: rem(25),
    },
    media(SMALL_BREAKPOINT, {
      marginTop: rem(90),
      marginBottom: rem(112),
    }),
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(120),
      marginBottom: rem(120),
    }),
    before(
      media(MEDIUM_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        top: rem(32),
        left: rem(32),
        width: rem(129),
        height: rem(128),
        zIndex: 1,
        backgroundImage: `url(${generateImagePath('illu_home_clientsupport-left_desktop.png')})`,
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }),
    ),
    after(
      media(MEDIUM_BREAKPOINT, {
        content: ' ',
        display: 'block',
        position: 'absolute',
        bottom: rem(84),
        right: rem(12),
        width: rem(149),
        height: rem(156),
        zIndex: 1,
        backgroundImage: `url(${generateImagePath('illu_home_clientsupport-right_desktop.png')})`,
        backgroundPosition: 'left bottom',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }),
    ),
  ),
  wrapper: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: rem(50),
      borderRadius: rem(5),
      ...column(1),
    },
    media(SMALL_BREAKPOINT, {
      paddingTop: rem(60),
      paddingRight: DEFAULT_GUTTER,
      paddingBottom: rem(70),
      paddingLeft: DEFAULT_GUTTER,
      border: rem(1) + ' solid ' + NEUTRAL_COLOR_20,
    }),
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(60),
      paddingLeft: DEFAULT_GUTTER * 4,
      paddingRight: DEFAULT_GUTTER * 4,
      ...column(10 / 12),
      ...offset(1 / 12),
    }),
  ),
  title: merge(
    {
      margin: 'auto',
      color: NEUTRAL_COLOR_90,
      textAlign: 'center',
      maxWidth: rem(300),
    },
    media(SMALL_BREAKPOINT, {
      maxWidth: rem(500),
    }),
  ),
  content: {
    textAlign: 'center',
    marginTop: rem(28),
    marginBottom: rem(28),
  },
})

export default styles
