/* @flow */

import { rem } from 'polished'
import { StyleSheet } from 'glamor/aphrodite'
import { fonts, colors } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR } = fonts
const { NEUTRAL_COLOR_50 } = colors

const styles = StyleSheet.create({
  main: {
    ...LIBRE_FRANKLIN_REGULAR,
    fontSize: rem(14),
    color: NEUTRAL_COLOR_50,
  },
})

export default styles
