/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import TextInput from './../TextInput'
import Button from './../Button'
import styles from './styles'

type Props = {
  className?: string,
  inputPlaceholder: string,
  submitButtonText: string,
  submitButtonLink: string,
}

type State = {|
  email: ?string,
|}

export default class EmailiForm extends React.Component<Props, State> {
  state = {
    email: null,
  }

  onChangeField = (name: string, value: ?string) => {
    this.setState({
      [name]: value,
    })
  }

  render() {
    const { className, inputPlaceholder, submitButtonText, submitButtonLink } = this.props
    return (
      <div className={merge(css(styles.container), className)}>
        <TextInput
          name="email"
          inputClassName={css(styles.input)}
          placeholder={inputPlaceholder}
          type="email"
          onChange={this.onChangeField}
        />
        <Button
          size="large"
          to={submitButtonLink}
          state={this.state}
          className={css(styles.button)}
        >
          {submitButtonText}
        </Button>
      </div>
    )
  }
}
