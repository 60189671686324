/* @flow */
/* eslint indent: 0 */

import * as React from 'react'
import { merge, css } from 'glamor'
import CalloutCardTitle from './../CalloutCardTitle'
import CalloutCardLabel from './../CalloutCardLabel'
import Image from './../Image'
import Paragraph from './../Paragraph'
import Button from './../Button'
import styles from './styles'

type Appearance = 'light' | 'dark' | 'fantasy'

type Size = 'small' | 'normal' | 'large' | 'huge'

type Props = {
  className?: string,
  contentClassName?: string,
  columnMediaClassName?: string,
  mediaClassName?: string,
  size?: Size,
  appearance?: Appearance,
  children?: React$Node,
  direction: 'ltr' | 'rtl',
  label?: string,
  title?: string,
  renderLabel?: (label?: string) => React$Node,
  renderTitle?: (title?: string, appearance: Appearance, size: Size) => React$Node,
  content?: string,
  buttonText?: string,
  buttonLink?: string,
  media?: string,
  mediaSize?: Size,
  mediaElevation?: boolean,
  mediaLazy?: boolean,
  layout?: 'content-forward',
  alt?: string,
}

export default class CalloutCard extends React.Component<Props> {
  static defaultProps = {
    direction: 'ltr',
    appearance: 'light',
    size: 'normal',
    mediaSize: 'normal',
    mediaLazy: true,
    renderTitle: (title?: string, appearance: Appearance, size: Size) => (
      <CalloutCardTitle
        size={size}
        className={css(
          styles.title,
          appearance === 'light' && styles.lightTitle,
          appearance === 'dark' && styles.darkTitle,
        )}
      >
        {title}
      </CalloutCardTitle>
    ),
    renderLabel: (label?: string) => (
      <CalloutCardLabel className={css(styles.label)}>{label}</CalloutCardLabel>
    ),
  }

  render() {
    const {
      className,
      contentClassName,
      media,
      columnMediaClassName,
      mediaClassName,
      mediaElevation,
      mediaLazy,
      appearance,
      size,
      renderLabel,
      label,
      renderTitle,
      title,
      content,
      children,
      direction,
      mediaSize,
      buttonText,
      buttonLink,
      alt,
    } = this.props
    return (
      <div
        className={merge(
          css(
            styles.container,
            size === 'large' && styles.largeContainer,
            appearance === 'dark' && styles.darkContainer,
            appearance === 'fantasy' && styles.fantasyContainer,
            mediaSize === 'huge' && styles.containerWithHugeMedia,
            direction === 'ltr' && styles.containerLtr,
            direction === 'rtl' && styles.containerRtl,
          ),
          className,
        )}
      >
        <div
          className={merge(
            css(styles.columnContent, appearance === 'fantasy' && styles.fantasyContent),
            contentClassName,
          )}
        >
          {/* $FlowFixMe */}
          {renderLabel(label)}
          {/* $FlowFixMe */}
          {renderTitle(title, appearance, size)}
          <Paragraph
            size={size}
            className={css(
              styles.content,
              appearance === 'light' && styles.lightParagraph,
              appearance === 'dark' && styles.darkParagraph,
              size === 'large' && styles.largeParagraph,
            )}
          >
            {content}
          </Paragraph>
          {buttonText &&
            buttonLink && (
              <Button className={css(styles.button)} to={buttonLink}>
                {buttonText}
              </Button>
            )}
          {children}
        </div>
        <div
          className={merge(
            css(
              styles.columnMedia,
              direction === 'ltr' && styles.columnMediaLtr,
              direction === 'rtl' && styles.columnMediaRtl,
            ),
            columnMediaClassName,
          )}
        >
          {media && (
            <Image
              src={media}
              alt={alt}
              lazy={mediaLazy}
              className={merge(css(
                styles.media,
                children && styles.mediaWithChildrenOnIt,
                mediaElevation && styles.mediaElevation,
                mediaElevation && direction === 'ltr' && styles.mediaElevationLtr,
                mediaElevation && direction === 'rtl' && styles.mediaElevationRtl,
                mediaSize === 'huge' && styles.hugeMedia,
                mediaSize === 'huge' && direction === 'ltr' && styles.hugeMediaLtr,
                mediaSize === 'huge' && direction === 'rtl' && styles.hugeMediaRtl,
                mediaSize === 'large' && styles.largeMedia,
              ), mediaClassName)}
            />
          )}
        </div>
      </div>
    )
  }
}
