/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { colors, breakpoints } from './../../theme'

const { NEUTRAL_COLOR_50 } = colors
const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  li: merge(
    {
      display: 'flex',
      marginTop: 6,
      marginBottom: 6,
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: 10,
      marginBottom: 10,
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: 12,
      marginBottom: 12,
    }),
  ),
  icon: merge(
    {
      display: 'block',
      marginTop: rem(2),
      marginRight: rem(8),
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(3),
      marginRight: rem(16),
    }),
  ),
  span: {
    flex: 1,
    color: NEUTRAL_COLOR_50,
  },
})

export default styles
