/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import NavBarDropdown from './../NavBarDropdown'
import styles from './styles'

type Props = {
  children?: React$Node,
  className?: string,
}

type State = {
  dropdown: {
    isOpen: boolean,
    activeIndex: number,
  },
}

export default class NavBar extends React.Component<Props, State> {
  timer: ?TimeoutID

  state = {
    dropdown: {
      isOpen: false,
      activeIndex: -1,
    },
  }

  handleOpenDropdown = (index?: number) => {
    if (this.timer) clearTimeout(this.timer)
    this.setState(prevState => ({
      dropdown: {
        ...prevState.dropdown,
        isOpen: true,
        activeIndex: index !== undefined ? index : prevState.dropdown.activeIndex,
      },
    }))
  }

  handleCloseDropdown = (index?: number) => {
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState(prevState => ({
        dropdown: {
          ...prevState.dropdown,
          isOpen: false,
          activeIndex: -1,
        },
      }))
    }, 250)
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.dropdown !== nextState.dropdown
  }

  render() {
    const { children, className } = this.props
    const { dropdown } = this.state

    if (!children) return null
    return (
      <nav>
        <ul className={merge(css(styles.list), className)}>
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
              active: dropdown.activeIndex === index,
              className: index > 0 && css(styles.itemWithOffset),
              handleOpenDropdown: () => this.handleOpenDropdown(index),
              handleCloseDropdown: () => this.handleCloseDropdown(index),
            })
          })}
        </ul>
        <NavBarDropdown
          {...dropdown}
          handleOpenDropdown={this.handleOpenDropdown}
          handleCloseDropdown={this.handleCloseDropdown}
          className={css(styles.dropdown)}
        >
          {React.Children.map(children, (child, index) => {
            const { props } = child
            if (!Array.isArray(props.children)) return null
            const [_, dropdown] = props.children
            return dropdown
          })}
        </NavBarDropdown>
      </nav>
    )
  }
}
