/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge, firstOfType } from 'glamor'
import { rem } from 'polished'
import { breakpoints, utils } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints
const { reverseToMaxBreakpoint } = utils

const styles = StyleSheet.create({
  container: merge({
    display: 'flex',
    flexDirection: 'column',
  }),
  centeredContainer: {
    justifyContent: 'center',
  },
  group: merge(
    media(LARGE_BREAKPOINT, {
      display: 'flex',
      flexDirection: 'row',
    }),
  ),
  input: merge(
    {
      width: '100%',
      boxSizing: 'border-box',
      marginBottom: 6,
    },
    media(reverseToMaxBreakpoint(MEDIUM_BREAKPOINT), {
      textAlign: 'center',
    }),
    media(LARGE_BREAKPOINT, {
      marginBottom: 12,
    }),
    firstOfType(
      media(LARGE_BREAKPOINT, {
        marginRight: 12,
      }),
    ),
  ),
  actions: merge(
    {
      display: 'flex',
      flexDirection: 'column',
    },
    media(LARGE_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  link: merge(
    {
      marginTop: rem(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      marginTop: rem(6),
    }),
    media(LARGE_BREAKPOINT, {
      marginTop: rem(2),
    }),
  ),
  span: {
    marginRight: rem(4),
  },
  button: merge(
    {
      width: '100%',
      marginTop: rem(6),
    },
    media(MEDIUM_BREAKPOINT, {
      flex: 1,
      width: 'auto',
      marginRight: rem(24),
      marginTop: 0,
    }),
  ),
})

export default styles
