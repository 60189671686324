/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { grid, utils } from './../../theme'

const { column, row } = grid
const { reverseToMaxBreakpoint } = utils

const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  container: merge(
    {
      alignItems: 'flex-start',
      marginTop: rem(12),
      marginBottom: rem(12),
    },
    media(reverseToMaxBreakpoint(NAV_BAR_MAJOR_BREAKPOINT), row()),
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      margin: 0,
    }),
  ),
  label: merge(column(5 / 12), media(NAV_BAR_MAJOR_BREAKPOINT, column(1))),
  plainLabel: merge(
    {
      ...column(1),
      marginTop: 12,
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      marginTop: 0,
    }),
  ),
  dropdown: merge(
    {
      ...column(7 / 12),
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'none',
    }),
  ),
})

export default styles
