/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import { Twitter, Facebook, Mail, Linkedin } from 'react-social-sharing'

import styles from './styles'

type Props = {
  message: string,
  url: string,
  prefix?: string,
}

export default function Share(props: Props) {
  return (
    <div className={css(styles.container)}>
      <span className={css(styles.label)}>{props.prefix}</span>
      <Mail
        className={css(styles.item)}
        small={true}
        message={`${props.message} via @winofrance`}
        link={props.url}
      />
      <Twitter
        className={css(styles.item)}
        small={true}
        message={`${props.message} via @winofrance`}
        link={props.url}
      />
      <Facebook
        className={css(styles.item)}
        small={true}
        message={props.message}
        link={props.url}
      />
      <Linkedin
        className={css(styles.item)}
        small={true}
        message={props.message}
        link={props.url}
      />
    </div>
  )
}
