/* @flow */

import * as React from 'react'
import { merge, css } from 'glamor'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Button from './../Button'
import Wrapper from './../Wrapper'
import styles from './styles'

type Props = {
  className?: string,
  title: string,
  content: string,
  buttonText: string,
  buttonOnClick: Function,
}

export default function Support(props: Props) {
  const { className, title, content, buttonText, buttonOnClick } = props
  return (
    <Wrapper className={merge(css(styles.root), className)}>
      <div className={css(styles.container)}>
        <div className={css(styles.wrapper)}>
          <Title level={2} className={css(styles.title)}>
            {title}
          </Title>
          <Paragraph className={css(styles.content)}>{content}</Paragraph>
          <div className={css(styles.button)}>
            <Button type="primary" appearance="light" onClick={buttonOnClick}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
