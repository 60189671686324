/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge } from 'glamor'
import { rem } from 'polished'
import { fonts, breakpoints, colors } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR } = fonts
const { LARGE_BREAKPOINT, SMALL_BREAKPOINT } = breakpoints
const { DANGER_COLOR_50 } = colors

const styles = StyleSheet.create({
  form: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      position: 'relative',
      width: '100%',
    },
    media(LARGE_BREAKPOINT, {
      flexDirection: 'row',
    }),
    media(SMALL_BREAKPOINT, {
      width: '50%',
    })
  ),
  centeredForm: {
    justifyContent: 'center',
  },
  input: merge(
    {
      marginBottom: 2,
      textAlign: 'center',
    },
    media(LARGE_BREAKPOINT, {
      flex: 1,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginBottom: 0,
      textAlign: 'left',
    }),
  ),
  button: merge(
    {
      marginTop: rem(6),
      width: '100%',
    },
    media(LARGE_BREAKPOINT, {
      flex: 1,
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
    media(LARGE_BREAKPOINT, {
      paddingTop: rem(16),
    }),
  ),
  error: merge(
    {
      display: 'inline-flex',
      marginTop: rem(6),
      marginBottom: rem(6),
      ...LIBRE_FRANKLIN_REGULAR,
      color: DANGER_COLOR_50,
      fontSize: rem(11),
    },
    media(LARGE_BREAKPOINT, {
      position: 'absolute',
      left: 0,
      bottom: rem(-30),
    })
  ),
})

export default styles
