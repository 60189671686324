/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  children: React$Node,
}

export default function FooterList(props: Props) {
  const { children } = props
  return <ul className={css(styles.wrapper)}>{children}</ul>
}
