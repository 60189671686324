import * as React from 'react'
import { Link } from 'gatsby'
import { css, merge } from 'glamor'
import styles from './styles'
import Button from '../Button'

type Props = {
  bannerTitle: string,
  bannerImage: string,
  bannerText: string,
  buttonLabel: string,
  buttonLink: string,
  appearance: string,
  target?: string,
  state?: any,
}

export default function PostBodyBanner(props: Props) {
  const {
    bannerTitle,
    bannerImage,
    bannerText,
    buttonLabel,
    buttonLink,
    target,
    appearance,
    state,
  } = props

  return (
    <Link to={buttonLink} target={target} state={state} className={css(styles.link)}>
      <div className={css(styles.global)}>
        <img className={css(styles.bannerImg)} src={bannerImage} alt={bannerTitle} />
        <div className={css(styles.bannerTextContainer)}>
          <h2 className={merge(css(styles.bannerTexts), css(styles[appearance]))}>{bannerTitle}</h2>
          <p className={merge(css(styles.bannerTexts), css(styles[appearance]))}> {bannerText}</p>
        </div>
        <div className={css(styles.bannerButtonContainer)}>
          <Button to={buttonLink} target={target} size="huge" state={state}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </Link>
  )
}
