/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, parent } from 'glamor'
import { colors, fonts } from './../../theme'
import { rem } from 'polished'

const { BRAND_COLOR_50 } = colors
const { LIBRE_FRANKLIN_SEMIBOLD } = fonts

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    top: 1,
    ...LIBRE_FRANKLIN_SEMIBOLD,
    color: BRAND_COLOR_50,
    fontSize: rem(16),
  },
  icon: merge(
    {
      marginLeft: rem(6),
      transition: 'transform 200ms ease',
    },
    parent(':hover >', {
      transform: `translateX(${rem(8)})`,
    }),
  ),
})

export default styles
