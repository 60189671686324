/* @flow */

type Direction = 'left' | 'right'

export function goTo(currentIndex: number, length: number, direction: Direction) {
  if (direction === 'left') {
    return currentIndex === 0 ? length - 1 : (currentIndex - 1) % length
  }
  return (currentIndex + 1) % length
}
