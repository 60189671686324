/* @flow */

import * as React from 'react'
import styles from './styles'
import { css } from 'glamor'
import Image from './../Image'

type Props = {
  label: string,
  media: string,
  alt?: string,
}

export default function SliderTab(props: Props) {
  const { media, alt } = props
  return <Image className={css(styles.media)} src={media} alt={alt} />
}
