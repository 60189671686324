/* @flow */

import { buildFontFace } from './utils'

// Main font - Archivo
export const ARCHIVO_REGULAR = buildFontFace('Archivo-Regular', 400)
export const ARCHIVO_SEMIBOLD = buildFontFace('Archivo-SemiBold', 600)
export const ARCHIVO_BOLD = buildFontFace('Archivo-Bold', 600)

// Secondary font - Libre Franklin
export const LIBRE_FRANKLIN_REGULAR = buildFontFace('LibreFranklin-Regular', 400)
export const LIBRE_FRANKLIN_SEMIBOLD = buildFontFace('LibreFranklin-SemiBold', 600)
export const LIBRE_FRANKLIN_BOLD = buildFontFace('LibreFranklin-Bold', 600)

// Other font - Baskerville
export const BASKERVILLE_SEMIBOLD = buildFontFace('Baskerville-SemiBold', 400)

// Styleguid font
export const FIRASANS_MEDIUM = buildFontFace('FiraSans-Medium', 500)
