/* @flow */

import * as React from 'react'
import { css } from 'glamor'
import styles from './styles'

type Props = {
  tint: string,
}

export default function Separator(props: Props) {
  const { tint } = props
  return <div className={css(styles.main)} style={{ backgroundColor: tint }} />
}
