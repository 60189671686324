import { StyleSheet } from 'glamor/aphrodite'
import { media, merge, hover } from 'glamor'
import { colors, breakpoints, shadows } from './../../theme'
import { rem, rgba } from 'polished'

const { NEUTRAL_COLOR_90 } = colors
const { BLACK_SHADOW_85 } = shadows

const { MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  global: merge(
    {
      lineHeight: 0,
      display: 'flex',
      overflow: 'hidden',
      width: '100%',
      maxHeight: rem(350),
      ...BLACK_SHADOW_85,
      borderRadius: rem(5),
      boxSizing: 'border-box',
      margin: `${rem(20)} 0`,
      transition: 'box-shadow ease .2s, transform ease .2s',
    },
    hover({
      boxShadow: `0 2px 30px ${rgba(NEUTRAL_COLOR_90, 0.175)}`,
      transform: 'translateY(-3px)',
    }),
  ),
  img: {
    width: '100%',
    height: 'auto',
  },
})

export default styles
