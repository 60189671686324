/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import { Link } from 'gatsby'
import Icon from './../Icon'
import { colors } from './../../theme'
import styles from './styles'

const { BRAND_COLOR_50, NEUTRAL_COLOR_80 } = colors

type Props = {
  children: string,
  to?: string,
  className?: string,
  hasDropdown?: boolean,
  active?: boolean,
  handleOpenDropdown?: () => void,
  handleCloseDropdown?: () => void,
}

export default class NavBarLabel extends React.Component<Props> {
  onMouseEnter = () => {
    const { to, handleOpenDropdown } = this.props
    if (!to && typeof handleOpenDropdown === 'function') {
      handleOpenDropdown()
    }
  }

  onMouseLeave = () => {
    const { to, handleCloseDropdown } = this.props
    if (!to && typeof handleCloseDropdown === 'function') {
      handleCloseDropdown()
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    return this.props.active !== nextProps.active
  }

  render() {
    const { children, to, className, hasDropdown, active } = this.props
    let TextComponent = Link
    if (!to) TextComponent = 'span'
    if (to && to.includes(':')) TextComponent = 'a'
    return (
      <TextComponent
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={merge(css(styles.text, to && styles.a), className)}
        to={to}
        href={to}
      >
        {children}
        {hasDropdown && (
          <Icon
            name="arrow"
            color={active ? BRAND_COLOR_50 : NEUTRAL_COLOR_80}
            className={css(styles.icon, active && styles.hoveredIcon)}
          />
        )}
      </TextComponent>
    )
  }
}
