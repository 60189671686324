/* @flow */

import { withPrefix } from 'gatsby'
import { css } from 'glamor'

type FontFace = {
  fontFamily: string,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
}

export function buildFontFace(name: string, weight: number): FontFace {
  return {
    fontFamily: css.fontFace({
      fontFamily: name,
      fontStyle: 'normal',
      fontWeight: weight,
      src: `url(${withPrefix(`fonts/${name}.ttf`)}) format('truetype')`,
    }),
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
  }
}

export function reverseToMaxBreakpoint(breakpoint: string) {
  return breakpoint.replace(/\bmin\b/g, 'max')
}

export function generateImagePath(path: string) {
  return withPrefix(`images/${path}`)
}

const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export { flexCenter }
