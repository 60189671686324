const devHubSpotConf = {
  SUBMIT_WEBFORM_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/',
  PORTAL_ID: '26751448',
  consent: (response = true) => ({
    consentToProcess: response,
    text:
      'En cliquant sur « Envoyer » ci-dessous, vous autorisez l’entreprise wino.fr à stocker et traiter les données personnelles soumises ci-dessus afin qu’elle vous fournisse le contenu demandé.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 164345385,
        text: "J'accepte de recevoir d'autres communications de wino.fr.",
      },
      {
        value: true,
        subscriptionTypeId: 157606021,
        text: "J'accepte de recevoir d'autres communications de wino.fr.",
      },
    ],
  }),
  forms: {
    demo: {
      FORM_ID: 'b891edde-9975-49df-be5a-36f185a082c9',
    },
    contact: {
      FORM_ID: '700068eb-f0de-4e87-b426-f7941e245a26',
    },
    quote: {
      FORM_ID: '5ae96c23-f557-4281-855f-86c251cf4164',
    },
  },
}

const prodHubSpotConf = {
  SUBMIT_WEBFORM_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/',
  PORTAL_ID: '26472724',
  consent: (response = true) => ({
    consentToProcess: response,
    text:
      'En cliquant sur « Envoyer » ci-dessous, vous autorisez l’entreprise wino.fr à stocker et traiter les données personnelles soumises ci-dessus afin qu’elle vous fournisse le contenu demandé.',
    communications: [
      {
        value: true,
        subscriptionTypeId: 155865768,
        text: "J'accepte de recevoir d'autres communications de wino.fr.",
      },
      {
        value: true,
        subscriptionTypeId: 157606021,
        text: "J'accepte de recevoir d'autres communications de wino.fr.",
      },
    ],
  }),
  forms: {
    demo: {
      FORM_ID: '4b713cd8-ac06-4a53-91ca-a3aa6b2a77fe',
    },
    contact: {
      FORM_ID: 'c4f17319-fae7-4a9a-9758-a1246955bfc8',
    },
    quote: {
      FORM_ID: '62c4dbcb-9dcc-4505-ac14-d50a764ee8f5',
    },
  },
}

export default {
  form: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    COMPANY_NAME: 'companyName',
    EMAIL: 'email',
    PHONE: 'phone',
    CODE: 'code',
    RECAPTCHA: 'recaptcha',
  },
  lambda: {
    PIPEDRIVE: `/.netlify/functions/pipedrive`,
  },
  plezi: {
    CREATE_CONTACT_AFTER_WEBFORM_URI: 'https://app.plezi.co/api/v1/create_contact_after_webform',
    TENANT_ID: '6021c87b54067a20d7f98f8e',
    forms: {
      demo: {
        CONTENT_WEB_FORM_ID: '60488d37d59cbc3224e0d488',
        FORM_ID: '6041ff77d59cbc320de0168d',
      },
      newsletter: {
        CONTENT_WEB_FORM_ID: '60fea471d59cbc39e8d91385',
        FORM_ID: '6021c88554067a20d7f9903c',
      },
      contact: {
        CONTENT_WEB_FORM_ID: '6021c88554067a20d7f99041',
        FORM_ID: '6021c88354067a20d7f99034',
      },
      devis: {
        CONTENT_WEB_FORM_ID: '6149fab9d59cbc1a6780830d',
        FORM_ID: '6149f829d59cbc1a9e8082e9',
      },
    },
  },
  hubspot: process.env.NODE_ENV === 'development' ? devHubSpotConf : prodHubSpotConf,
}
