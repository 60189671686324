/* @flow */

import React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'
import Title from './../Title'
import Span from './../Span'
import NewsletterForm from './../NewsletterForm'

type Props = {
  className?: string,
  title: string,
  subtitle: string,
  inputPlaceholderText: string,
  buttonText: string,
  buttonLink: string,
}

export default function NewsletterCard(props: Props) {
  const { title, subtitle, inputPlaceholderText, buttonText } = props
  return (
    <div className={merge(css(styles.container))}>
      <div className={merge(css(styles.texts))}>
        <Title level={5} className={css(styles.title)}>{title}</Title>
        <Span className={css(styles.span)}>{subtitle}</Span>
      </div>
      <NewsletterForm
        className={css(styles.form)}
        align="center"
        inputAppearance="dark"
        inputPlaceholder={inputPlaceholderText}
        buttonText={buttonText}
      />
    </div>
  )
}
