/* @flow */

import * as React from 'react'
import { colors } from './../../theme'

const { SECONDARY_COLOR_50, NEUTRAL_COLOR_90 } = colors

type Props = {
  className?: string,
  tint?: string,
  brush?: boolean,
  media?: string,
  children: React$Node,
}

function getBrush(tint: string) {
  switch (tint) {
    case SECONDARY_COLOR_50:
      return 'secondary'
    case NEUTRAL_COLOR_90:
      return 'neutral'
    default:
      return ''
  }
}

export default function Swatch(props: Props) {
  const { className, tint, brush, media, children } = props
  return (
    <div
      className={className} // $FlowFixMe
      style={Object.assign(
        {},
        tint && { backgroundColor: tint },
        tint && brush && { backgroundImage: `url(../images/patterns/${getBrush(tint)}.png)` },
        media && {
          backgroundImage: `url(../images/${media}.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      )}
    >
      {children}
    </div>
  )
}
