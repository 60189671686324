/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import styles from './styles'

type Props = {
  className?: string,
  inputClassName?: string,
  type?: string,
  name?: string,
  label?: string,
  placeholder?: string,
  size?: 'normal' | 'large',
  value?: mixed,
  error?: ?string | ?boolean,
  onChange?: (name: string, value: string) => void,
}

export default class TextInput extends React.Component<Props> {
  static defaultProps = { size: 'normal', type: 'text' }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { onChange, name } = this.props
    if (typeof onChange === 'function' && name) {
      // $FlowFixMe
      const value = event.target.value
      onChange(name, value)
    }
  }

  render() {
    const {
      className,
      inputClassName,
      size,
      label,
      placeholder,
      name,
      type,
      error,
      value,
    } = this.props
    return (
      <div className={merge(css(styles.container), className)}>
        {label && (
          <label htmlFor={name} className={css(styles.label)}>
            {label}
          </label>
        )}
        <input
          id={name}
          name={name}
          className={merge(
            css(
              styles.input,
              size === 'normal' && styles.normalInput,
              size === 'large' && styles.largeInput,
            ),
            inputClassName,
          )}
          type={type}
          {...(value !== undefined ? { value } : {})}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        {error && <span className={css(styles.error)}>{error}</span>}
      </div>
    )
  }
}
