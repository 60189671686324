/* @flow */

import * as React from 'react'
import Slick from 'react-slick'
import { merge, css } from 'glamor'
import { colors } from './../../theme/'
import SliderNav from './../SliderNav'
import Icon from './../Icon'
import { goTo } from './utils'
import styles from './styles'

const { NEUTRAL_COLOR_50 } = colors

const Empty = () => <div />

type Props = {
  className?: string,
  children: React$Node,
}

type State = {|
  index: number,
|}

type Direction = 'left' | 'right'

export default class Slider extends React.Component<Props, State> {
  slick: Slick = null

  state = { index: 0 }

  handleRef = (c: Slick) => {
    this.slick = c
  }

  handleChangeTab = (index: number) => {
    this.setState({ index })
    if (this.slick) this.slick.slickGoTo(index)
  }

  onSwipe = (reversedDirection: Direction) => {
    const { children } = this.props
    const { index } = this.state
    const childrenLength = React.Children.count(children)
    const direction = reversedDirection === 'left' ? 'right' : 'left'
    this.setState({ index: goTo(index, childrenLength, direction) })
  }

  renderArrow = (direction: Direction) => {
    const { children } = this.props
    const { index } = this.state
    const childrenLength = React.Children.count(children)
    return (
      <div
        role="menuitem"
        tabIndex={index}
        className={css(
          styles.arrow,
          styles[`${direction}Arrow`],
          ((direction === 'left' && index === 0) ||
            (direction === 'right' && index === childrenLength - 1)) &&
            styles.disabledArrow,
        )}
        onClick={() => this.handleChangeTab(goTo(index, childrenLength, direction))}
      >
        <Icon name={direction} size={24} color={NEUTRAL_COLOR_50} />
      </div>
    )
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.index !== nextState.index
  }

  render() {
    const { children, className } = this.props
    return (
      <div className={merge(css(styles.main), className)}>
        <SliderNav
          labels={React.Children.map(children, child => child.props.label)}
          handleChangeTab={this.handleChangeTab}
          activeIndex={this.state.index}
        />
        <div className={css(styles.content)}>
          {this.renderArrow('left')}
          <div className={css(styles.slick)}>
            <Slick
              ref={this.handleRef}
              onSwipe={this.onSwipe}
              speed={400}
              prevArrow={<Empty />}
              nextArrow={<Empty />}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {children}
            </Slick>
          </div>
          {this.renderArrow('right')}
        </div>
      </div>
    )
  }
}
