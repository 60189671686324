/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { media, merge } from 'glamor'
import { rem } from 'polished'
import { breakpoints } from './../../theme'

const { MEDIUM_BREAKPOINT, LARGE_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  form: merge(
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    media(MEDIUM_BREAKPOINT, {
      flexDirection: 'row',
    }),
  ),
  centeredForm: {
    justifyContent: 'center',
  },
  input: merge(
    {
      marginBottom: 2,
      textAlign: 'center',
    },
    media(MEDIUM_BREAKPOINT, {
      flex: 1,
      marginBottom: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      textAlign: 'left',
    }),
  ),
  button: merge(
    {
      marginTop: rem(6),
      width: '100%',
    },
    media(MEDIUM_BREAKPOINT, {
      flex: 1,
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }),
    media(LARGE_BREAKPOINT, {
      paddingTop: rem(16),
    }),
  ),
})

export default styles
