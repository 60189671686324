/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import TextInput from './../TextInput'
import Button from './../Button'
import styles from './styles'

type Props = {
  className?: string,
  align?: 'left' | 'center',
  inputPlaceholder: string,
  buttonAppearance?: 'light' | 'dark',
  buttonType?: 'primary' | 'secondary',
  buttonText: string,
  buttonLink: string,
}

type State = {|
  email: ?string,
|}

export default class InboundForm extends React.Component<Props, State> {
  state = {
    email: null,
  }

  onChangeField = (name: string, value: ?string) => {
    this.setState({
      [name]: value,
    })
  }

  render() {
    const {
      className,
      align,
      inputPlaceholder,
      buttonAppearance,
      buttonType,
      buttonText,
      buttonLink,
    } = this.props
    return (
      <div
        className={merge(css(styles.form, align === 'center' && styles.centeredForm), className)}
      >
        <TextInput
          className={css(styles.input)}
          size="large"
          placeholder={inputPlaceholder}
          name="email"
          type="email"
          onChange={this.onChangeField}
        />
        <Button
          className={css(styles.button)}
          type={buttonType}
          appearance={buttonAppearance}
          to={buttonLink}
          state={this.state}
        >
          {buttonText}
        </Button>
      </div>
    )
  }
}
