/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover, before, after, media } from 'glamor'
import { rem, rgba } from 'polished'
import { colors, utils, breakpoints } from './../../theme'

const { BRAND_COLOR_50, SECONDARY_COLOR_50, NEUTRAL_COLOR_00, NEUTRAL_COLOR_90 } = colors
const { generateImagePath } = utils
const { MEDIUM_BREAKPOINT } = breakpoints

const styles = StyleSheet.create({
  container: merge(
    {
      paddingTop: rem(12),
      paddingRight: rem(28),
      paddingBottom: rem(44),
      paddingLeft: rem(28),
      borderRadius: rem(4),
      transition: 'box-shadow ease .2s, transform ease .2s',
      overflow: 'hidden',
    },
    hover({
      boxShadow: `0 2px 30px ${rgba(NEUTRAL_COLOR_90, 0.175)}`,
      transform: 'translateY(-3px)',
    }),
    before({
      content: ' ',
      display: 'block',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      bottom: '50%',
      right: 0,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top',
    }),
    after({
      content: ' ',
      display: 'block',
      position: 'absolute',
      zIndex: -1,
      top: '50%',
      left: 0,
      bottom: 0,
      right: 0,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
    }),
    media(MEDIUM_BREAKPOINT, {
      paddingTop: rem(28),
      paddingRight: rem(54),
      paddingBottom: rem(64),
      paddingLeft: rem(54),
    }),
  ),
  primaryContainer: merge(
    before({
      backgroundColor: BRAND_COLOR_50,
    }),
    after({
      backgroundColor: BRAND_COLOR_50,
    }),
  ),
  secondaryContainer: merge(
    before({
      backgroundColor: SECONDARY_COLOR_50,
    }),
    after({
      backgroundColor: SECONDARY_COLOR_50,
    }),
  ),
  offlineContainer: merge(
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_offline_bottom.png',
      )})`,
    }),
  ),
  complianceContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_compliance_top.png',
      )})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-pointofsales_compliance_bottom.png',
      )})`,
    }),
  ),
  multishopContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-dashboard_multishop_top.png',
      )})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-dashboard_multishop_bottom.png',
      )})`,
    }),
  ),
  everywhereContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-dashboard_everywheretime_top.png',
      )})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-dashboard_everywheretime_bottom.png',
      )})`,
    }),
  ),
  securityContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_security_top.png',
      )})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-business_security_bottom.png',
      )})`,
    }),
  ),
  freeContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath('abstractpattern_product-fidelity_free_top.png')})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-fidelity_free_bottom.png',
      )})`,
    }),
  ),
  oncashregisterContainer: merge(
    before({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-fidelity_oncashregister_top.png',
      )})`,
    }),
    after({
      backgroundImage: `url(${generateImagePath(
        'abstractpattern_product-fidelity_oncashregister_bottom.png',
      )})`,
    }),
  ),
  text: {
    color: NEUTRAL_COLOR_00,
  },
  paragraph: {
    marginTop: rem(-8),
    marginBottom: rem(24),
    opacity: 0.875,
  },
  link: merge(
    hover({
      color: NEUTRAL_COLOR_00,
      opacity: 0.75,
    }),
  ),
})

export default styles
