/* @flow */
/* eslint indent: 0 */

import * as React from 'react'
import { css, merge } from 'glamor'
import Title from './../Title'
import Paragraph from './../Paragraph'
import Link from './../Link'
import Image from './../Image'
import styles from './styles'

type Props = {
  className?: string,
  theme?: 'primary' | 'secondary',
  media?: string,
  title?: string,
  content?: string,
  hasLink?: boolean,
  linkTo?: string,
  linkText?: string,
  size?: 'large' | 'default',
  align?: 'center',
}

export default function Feature(props: Props) {
  const { className, theme, media, title, content, hasLink, linkText, align, size } = props
  return (
    <div
      className={merge(
        css(styles.container, align === 'center' && styles.centeredContainer),
        className,
      )}
    >
      {media && <Image className={css(styles.media)} src={media} lazy={false} />}
      {title && (
        <Title
          className={css(
            styles.title,
            theme === 'primary' && styles.primaryTitle,
            theme === 'secondary' && styles.secondaryTitle,
            size === 'large' && styles.largeTitle,
          )}
          level={3}
        >
          {title}
        </Title>
      )}
      {content && <Paragraph className={css(styles.content)}>{content}</Paragraph>}
      {hasLink &&
        linkText &&
        theme && (
          <Link className={css(styles.link)} type={theme}>
            {String(linkText)}
          </Link>
        )}
    </div>
  )
}
