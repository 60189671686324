/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, hover, media } from 'glamor'
import { rem } from 'polished'
import { fonts, colors, breakpoints } from './../../theme'

const { LIBRE_FRANKLIN_REGULAR, LIBRE_FRANKLIN_BOLD } = fonts
const { NEUTRAL_COLOR_80 } = colors
const { LARGE_BREAKPOINT } = breakpoints
const NAV_BAR_MAJOR_BREAKPOINT = `(min-width: ${rem(950)})`

const styles = StyleSheet.create({
  text: merge(
    {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: rem(16),
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      ...LIBRE_FRANKLIN_BOLD,
      color: NEUTRAL_COLOR_80,
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      ...LIBRE_FRANKLIN_REGULAR,
    }),
  ),
  a: merge(
    {
      cursor: 'pointer',
    },
    hover({
      color: NEUTRAL_COLOR_80,
      opacity: 0.625,
      textDecoration: 'underline',
    }),
  ),
  icon: merge(
    {
      display: 'none',
    },
    media(NAV_BAR_MAJOR_BREAKPOINT, {
      display: 'block',
      position: 'relative',
      top: rem(0),
      marginLeft: rem(4),
      transition: 'transform .25s ease-in-out',
    }),
    media(LARGE_BREAKPOINT, {
      marginLeft: rem(6),
    }),
  ),
  hoveredIcon: {
    transform: 'rotate(-180deg)',
  },
})

export default styles
