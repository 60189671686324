/* @flow */

import { utils } from './../../theme'

const { generateImagePath } = utils

export function parseSrc(src: string) {
  const filename = src.split('.')
  return filename
}

export function generateSrcPath(src: string, size: string, scale?: number) {
  const parsedSrc = parseSrc(src)
  return generateImagePath(`${parsedSrc[0]}_${size}${scale ? `@${scale}x` : ''}.${parsedSrc[1]}`)
}

export function generateSrc(src: string) {
  if (src.match(/^https:\/\/(.*)/)) return src

  return generateSrcPath(src, 'desktop')
}

export function generateSrcset(src: string) {
  if (/^(f|ht)tps?:\/\//i.test(src)) {
    return src
  }
  return `${generateSrcPath(src, 'mobile')} 320w,
          ${generateSrcPath(src, 'mobile', 2)} 560w,
          ${generateSrcPath(src, 'desktop')} 780w,
          ${generateSrcPath(src, 'desktop', 2)} 1024w`
}
