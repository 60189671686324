/* @flow */
/* Source > https://gist.github.com/bloodyowl/4c16d958d01a08712637ee16acb35038 */

import currentLanguage from './utils/currentLanguage'

const languages = {
  'en-EN': null,
  'fr-FR': require('./french.json'),
}

type Options = {
  plural?: boolean,
}

export function t(expression: string, options: Options = {}): string {
  if (!currentLanguage) return expression
  const translations = languages[currentLanguage]
  if (!translations) return expression
  const translation = translations.hasOwnProperty(expression)
    ? translations[expression]
    : expression
  if (translation && typeof translation !== 'string') {
    if (options.plural && 'plural' in translation) {
      return translation.plural
    }
    return translation.singular
  }
  return translation
}
