/* @flow */

import * as React from 'react'
import { rem } from 'polished'
import { css } from 'glamor'

export const DEFAULT_GUTTER = 20

export function row() {
  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
    width: `calc(100% + ${rem(DEFAULT_GUTTER)})`,
    marginLeft: rem(DEFAULT_GUTTER * -0.5),
    boxSizing: 'border-box',
  }
}

export function column(ratio: number) {
  const width = `calc(${ratio * 100}% - ${rem(DEFAULT_GUTTER)})`
  return {
    flexBasis: width,
    maxWidth: width,
    position: 'relative',
    marginRight: rem(DEFAULT_GUTTER / 2),
    marginLeft: rem(DEFAULT_GUTTER / 2),
    boxSizing: 'border-box',
  }
}

export function offset(ratio: number) {
  return {
    left: `${ratio * 100}%`,
    marginLeft: rem((ratio * 12 - 1 + 0.5) * DEFAULT_GUTTER),
  }
}

export function Row(props: { children: React$Node }) {
  const { children } = props
  return <div className={css(row())}>{children}</div>
}
