/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { merge, media } from 'glamor'
import { rem } from 'polished'
import { breakpoints, fonts } from './../../theme'

const { LARGE_BREAKPOINT } = breakpoints
const { ARCHIVO_REGULAR } = fonts

const styles = StyleSheet.create({
  title: {
    ...ARCHIVO_REGULAR,
    fontSize: rem(22),
  },
  ul: merge(
    {
      marginTop: rem(24),
      paddingRight: rem(24),
    },
    media(LARGE_BREAKPOINT, {
      paddingRight: rem(54),
    }),
  ),
  ulWithoutTitle: {
    marginTop: 0,
  },
})

export default styles
