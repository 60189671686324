/* @flow */

import * as React from 'react'
import { css, merge } from 'glamor'
import Link from 'gatsby-link'
import DropdownIcon from './../DropdownIcon'
import DropdownTitle from './../DropdownTitle'
import DropdownContent from './../DropdownContent'
import styles from './styles'

type Props = {
  className?: string,
  to?: string,
  renderIcon?: (name: string) => React$Node,
  renderTitle?: (children: string, active: boolean) => React$Node,
  renderContent?: (children: string) => React$Node,
  title: string,
  content?: string,
  icon?: string,
}

type State = {
  active: boolean,
}

export default class DropdownItem extends React.Component<Props, State> {
  state = {
    active: false,
  }

  static defaultProps = {
    renderIcon: (name: string) => <DropdownIcon name={name} />,
    renderTitle: (children: string, active: boolean) => (
      <DropdownTitle active={active}>{children}</DropdownTitle>
    ),
    renderContent: (children: string) => <DropdownContent>{children}</DropdownContent>,
  }

  onMouseEnter = () => {
    this.setState({ active: true })
  }

  onMouseLeave = () => {
    this.setState({ active: false })
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return this.state.active !== nextState.active
  }

  render() {
    const {
      className,
      to,
      icon,
      title,
      content,
      renderIcon,
      renderTitle,
      renderContent,
    } = this.props
    const { active } = this.state
    return (
      <Link
        to={to}
        className={merge(css(styles.container), className)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className={css(styles.icon)}>{icon && renderIcon && renderIcon(icon)}</div>
        <div className={css(styles.text)}>
          <div className={css(styles.title)}>{renderTitle && renderTitle(title, active)}</div>
          <div className={css(styles.content)}>
            {renderContent && content && renderContent(content)}
          </div>
        </div>
      </Link>
    )
  }
}
