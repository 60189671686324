/* @flow */

import { StyleSheet } from 'glamor/aphrodite'
import { rem } from 'polished'
import { colors, fonts } from './../../theme'

const { ARCHIVO_SEMIBOLD } = fonts
const { SECONDARY_COLOR_10, SECONDARY_COLOR_50 } = colors

const styles = StyleSheet.create({
  main: {
    display: 'block',
    paddingTop: rem(7),
    paddingRight: rem(10),
    paddingBottom: rem(6),
    paddingLeft: rem(10),
    borderRadius: rem(3),
    backgroundColor: SECONDARY_COLOR_10,
    ...ARCHIVO_SEMIBOLD,
    fontSize: rem(15),
    color: SECONDARY_COLOR_50,
  },
})

export default styles
